import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Landingpage from './Components/Landingpage/landing';
import "./App.css";
import Swap from "./Pages/Swap";
import Farms from "./Pages/farms";
import Pools from "./Pages/pools";
import Poolsbalance from "./Pages/poolsbalance";
import Assets from "./Pages/assets";
import Trade from "./Pages/trade";
import Optionpage from "./Pages/optionpage";
import Pooltypepage from "./Pages/pooltypepage";
import Margin from "./Pages/margin";
import Allcointokenform from "./Pages/Listyourproject/allcointokenform";
import Allexchangeform from "./Pages/Listyourproject/allexchangeform";
import SubmitRequest from "./Pages/SubmitRequest";
import SubmissionForm from "./Pages/SubmissionForm";
import WebsiteUrl from "./Pages/WebsiteUrl";
import Login from "./Layouts/Login";
import CoinPairPrice from "./Pages/CoinPairPrice";
import ViewPools from "./Pages/ViewPools";
import Bridges from "./Pages/bridges";
import Earn from "./Pages/Earn";
import Ponds from "./Pages/ponds";
import Chartpage from "./Pages/chartpage";
import Synthetics from "./Pages/synthetics";
import Dashboard from "./Components/Landingpage/dashboard";


function App() {
    
    return (
        <div className="App">

            <BrowserRouter >
            <Routes>
              <Route path="/" element={<Landingpage />} />
              <Route path="/farms" element={<Farms />} />
              <Route path="/swap" element={<Swap />} />
              <Route path="/pools" element={<Pools />} />
              <Route path="/lendandborrow" element={<Assets />} />
              <Route path="/poolsbalance" element={<Poolsbalance />} />
              <Route path="/spot" element={<Trade />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/option" element={<Optionpage />} />
              <Route path="/synthetics" element={<Synthetics />} />
              <Route path="/pooltype" element={<Pooltypepage />} />
              <Route path="/future" element={<Margin />} />
              <Route path="/cointoken" element={<Allcointokenform />} />
              <Route path="/exchange" element={<Allexchangeform />} />
              <Route path="/submit-request" element={<SubmitRequest />} />
              <Route path="/submission-form" element={<SubmissionForm />} />
              <Route path="/websiteurl" element={<WebsiteUrl />} />
              <Route path="/login" element={<Login />} />
              <Route path="/coinpairprice" element={<CoinPairPrice />} />
              <Route path="/view-pools" element={<ViewPools />} />
              <Route path="/bridge" element={<Bridges />} />
              <Route path="/earn" element={<Earn />} />
              <Route path="/pond" element={<Ponds />} />
              <Route path="/chartpage" element={<Chartpage />} />
            </Routes>
            </BrowserRouter>

        </div>
    );
}

export default App;