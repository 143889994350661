import React, { useState } from 'react';
import Marquee from "react-fast-marquee";
import Images from './Images';
import { MdClose } from "react-icons/md";

export default function Announcement() {
    const [parnetcard, setpartnercard] = useState([
        { "id": 1 }, { "id": 2 }, { "id": 3 }, { "id": 4 }, { "id": 5 }, { "id": 6 }, { "id": 7 }
    ]);
   const [announces,setAnnounces] = useState(true);

    return (

        // <div className='silderss slidepad mb-2 mb-sm-4'>
        //     <Marquee speed={80} delay={2} direction='left'>
        //         <div className="partnermarquee">
        //             {parnetcard.map((e, i) =>
        //                 <div className="partnerwidth">
        //                     <p className='mb-0'>Lorem Ipsum</p>
        //                 </div>
        //             )}
                    
        //         </div>
        //         <p className='mb-0'>I sent an email to my agency telling them that a mistake was made. I am to receive 9 additional hours above my normal pay, but that does not explain the amount in my account. The agency replied on Monday 6th March, 2017 morning, saying that it is a tax rebate, I did not know this was possible, I had to look up the word rebate in dictionary as English is not my mother tongue because I am originally from Netherland.
        //         </p>
        //         <p></p>
        //     </Marquee>
        //     <img src={Images.backlogo} className='backlogo' />
        //     <img src={Images.elogo} className='elogo' />
        // </div>

        <div className={announces ?'announce_whole mb-3':"d-none"}>
         <div className='rolling_track'>
            <div className='rolling_cont'>
                <p className='mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled,it to make a type specimen book. It has survived not only five centuries. </p>
            </div>
         </div>
         <MdClose className='announce_close' onClick={()=>setAnnounces(false)}/>
        </div>
    )
}
