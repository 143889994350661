import React, { useState, useEffect, useRef } from "react";
import { Modal, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem, Table } from "react-bootstrap";
import Images from "../Components/Images";
import Networkmodals from "./networkmodal";
import Switch from "react-switch";
import { Slider } from 'rsuite';
// import "rsuite/dist/rsuite.min.css";
import 'rsuite/dist/rsuite-no-reset.min.css';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Poolnetmodals from "./poolnetmodal";
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import { MdClose } from "react-icons/md";

function Longtab(props) {


    const [networkmodal, setNetworkmodal] = useState(false)
    const [types, setTypes] = useState(1)

    const [interchange, setInterchange] = useState(
        {
            id: 1, image: Images.eth, coinname: "WETH",
        }
    );
    const [intertwochange, setIntertwochange] = useState(
        {
            id: 2, image: Images.dai, coinname: "DAI"
        },
    );
    const onswap = () => {
        var changea = interchange
        var changeb = intertwochange
        setInterchange(changeb)
        setIntertwochange(changea)
    };

    const [termsTab, setTermsTab] = useState('market');
    const handleTabClick = (tab) => {
        setTermsTab(tab);
    };
    const [checked, setChecked] = useState(true);
    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };

    const [value1, setValue1] = useState(5);
    const handleStyle1 = {
        color: '#fff',
        fontSize: 12,
        width: 32,
        height: 22
    };
  
    const [poolnetmodal, setPoolnetmodal] = useState(false);
    const [tablelists, setTablelist] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 },
    ]);

    const [selectcollateral, setSelectcollateral] = useState({ id: 1, img: Images.eth, name: "WETH" });
    const [collateral, setCollateral] = useState([
        { id: 1, img: Images.eth, name: "WETH" }, { id: 2, img: Images.usdc, name: "USDC" }, { id: 3, img: Images.dai, name: "DAI" }
    ]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleSelect = (e) => {
        setSelectcollateral(e);
        setIsDropdownOpen(false); // Close the dropdown after selecting an item
    };

    const [limitopen, setLimitopen] = useState(false);



    // const [selectpercent, setSelectpercent] = useState({ per: "100" });
    const [percent, setPercent] = useState([
        { per: "10" },
        { per: "25" },
        { per: "50" },
        { per: "75" },
        { per: "100" }
    ]);
    const [filterRows, setFilterRows] = useState([
        { filters: "", isListVisible: false, selectpercent: { per: 100 } }
    ]);
    const listContainerRef = useRef(null);
    const addFilters = () => {
        if (filterRows.length > 0) {
            let totalPer = 0;
            // Calculate the total percentage used so far
            for (let i = 0; i < filterRows.length; i++) {
                totalPer += Number(filterRows[i]?.selectpercent?.per);
            }
            const remainingPer = 100 - totalPer;
            // Add a new filter row with the remaining percentage
            setFilterRows([...filterRows, { filters: "", isListVisible: false, selectpercent: { per: remainingPer } }]);
        }
    };
    // Calculate the total percentage to determine if the button should be disabled
    let totalPer = 0;
    filterRows.forEach(row => {
        totalPer += Number(row?.selectpercent?.per);
    });
    // Disable the add button if totalPer >= 100
    const isAddDisabled = totalPer >= 100;

    const removeFilters = (index) => {
        const list = [...filterRows];
        list.splice(index, 1);
        setFilterRows(list);
    };
    const handleInputClick = (index) => {
        const updatedRows = filterRows.map((row, i) =>
            i === index ? { ...row, isListVisible: !row.isListVisible } : { ...row, isListVisible: false }
        );
        setFilterRows(updatedRows);
    };
    const handleSelects = (index, selectedPercent) => {
        const updatedRows = filterRows.map((row, i) =>
            i === index ? { ...row, selectpercent: selectedPercent, isListVisible: false } : row
        );
        setFilterRows(updatedRows);
    };


    const [filterprofitRows, setFilterprofitRows] = useState([
        { filters: "", isListVisible: false, selectpercent: { per: 100 } }
    ]);
    const listProfitRef = useRef(null);
    const addFiltersprofit = () => {
        if (filterprofitRows.length > 0) {
            let totalprofitPer = 0;
            // Calculate the total percentage used so far
            for (let i = 0; i < filterprofitRows.length; i++) {
                totalprofitPer += Number(filterprofitRows[i]?.selectpercent?.per);
            }
            const remainingPer = 100 - totalprofitPer;
            // Add a new filter row with the remaining percentage
            setFilterprofitRows([...filterprofitRows, { filters: "", isListVisible: false, selectpercent: { per: remainingPer } }]);
        }
    };
    // Calculate the total percentage to determine if the button should be disabled
    let totalprofitPer = 0;
    filterprofitRows.forEach(row => {
        totalprofitPer += Number(row?.selectpercent?.per);
    });
    // Disable the add button if totalprofitPer >= 100
    const isAddprofitDisabled = totalprofitPer >= 100;

    const removeprofitFilters = (index) => {
        const list = [...filterprofitRows];
        list.splice(index, 1);
        setFilterprofitRows(list);
    };
    const handleprofitInputClick = (index) => {
        const updatedRows = filterprofitRows.map((row, i) =>
            i === index ? { ...row, isListVisible: !row.isListVisible } : { ...row, isListVisible: false }
        );
        setFilterprofitRows(updatedRows);
    };
    const handleprofitSelects = (index, selectedPercent) => {
        const updatedRows = filterprofitRows.map((row, i) =>
            i === index ? { ...row, selectpercent: selectedPercent, isListVisible: false } : row
        );
        setFilterprofitRows(updatedRows);
    };

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //         const updatedRows = filterRows.map(row => ({
    //             ...row,
    //             isListVisible: false
    //         }));
    //         setFilterRows(updatedRows);
    //     };
    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, [filterRows]);





    return (
        <>
            {networkmodal && <Networkmodals onDismiss={() => setNetworkmodal(false)} types={types} setInterchange={(e) => setInterchange(e)} setIntertwochange={(e) => setIntertwochange(e)} interchange={interchange} />}
            {poolnetmodal && <Poolnetmodals onDismiss={() => setPoolnetmodal(false)} />}
            <div>

                <ul className="listtab d-flex gap-4 mt-4">
                    <li className={termsTab === "market" ? "active fw-600" : ""} onClick={() => handleTabClick('market')}>Market</li>
                    <li className={termsTab === "limit" ? "active fw-600" : ""} onClick={() => handleTabClick('limit')}>Limit</li>
                    <li className={termsTab === "tp" ? "active fw-600" : ""} onClick={() => handleTabClick('tp')}>TP/SL</li>
                </ul>

                <div>

                    {termsTab != "tp" &&
                        <>
                            <div className="swap__blueBox p-2 p-sm-3 rounded-4">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div><h6 className="themeclr fw-600">Pay</h6><input type="text" className="form-control swap_input ps-0" value="0.00" /></div>
                                    <div>
                                        <h6 className="themeclr fw-600 text-end mb-2">Balance</h6>
                                        <Dropdown className="networkdrop text-end">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => { setTypes(2); setNetworkmodal(true) }}>
                                                <img src={intertwochange.image} className="imf-fluid ethimg" /> {intertwochange.coinname}
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                    </div>

                                </div>
                            </div>
                            <div className="text-center swapclick minus" onClick={() => onswap()}><img src={Images.swapimg} className="imf-fluid ehtimg" /></div>
                            <div className="swap__blueBox p-2 p-sm-3 rounded-4">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div><h6 className="themeclr fw-600">{props.tab}</h6><input type="text" className="form-control swap_input ps-0" value="0.00" /></div>
                                    <div>
                                        <h6 className="themeclr fw-600 text-end mb-2">Leverage: 0.50x</h6>
                                        <Dropdown className="networkdrop text-end">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" onClick={() => { setTypes(2); setNetworkmodal(true) }}>
                                                <img src={intertwochange.image} className="imf-fluid ethimg" /> {intertwochange.coinname}
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div></>}


                    {termsTab === "limit" &&
                        <div className="swap__blueBox p-2 p-sm-3 rounded-4 mt-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <div><h6 className="themeclr fw-600">Price</h6><input type="text" className="form-control swap_input ps-0" value="0.00" /></div>
                                <div>
                                    <h6 className="themeclr fw-600 text-end mb-2">Mark: $12,543</h6>
                                    <h5 className="themeclr fw-600 text-end mb-2">USD</h5>
                                </div>
                            </div>
                        </div>}

                    {termsTab === "tp" &&
                        <>
                            <div className="swap__blueBox p-2 p-sm-3 rounded-4 mt-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div><h6 className="themeclr fw-600">Close</h6><input type="text" className="form-control swap_input ps-0" value="0.00" /></div>
                                    <div>
                                        <h5 className="themeclr fw-600 text-end mb-0">USD</h5>
                                    </div>

                                </div>
                            </div>
                            <div className="swap__blueBox p-2 p-sm-3 rounded-4 mt-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div><h6 className="themeclr fw-600">Price</h6><input type="text" className="form-control swap_input ps-0" value="0.00" /></div>
                                    <div>
                                        <h6 className="themeclr fw-600 text-end mb-2">Mark: $12,543</h6>
                                        <h5 className="themeclr fw-600 text-end mb-2">USD</h5>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {termsTab != "tp" &&
                        <>
                            <div className="d-flex mt-3 align-items-center justify-content-between">
                                <p className="themeclr mb-0">Leverage slider</p>
                                <Switch
                                    onChange={handleChange}
                                    checked={checked}
                                    offColor="#162019"
                                    onColor="#1888b7"
                                    offHandleColor="#ffff"
                                    onHandleColor="#00e7fa"
                                    height={20}
                                    width={36}
                                    handleDiameter={18}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    className="react-switch"
                                    id="material-switch"
                                />
                            </div>
                            {checked ?
                                <div className="mt-3 mx-2">
                                    <Slider
                                        min={0}
                                        // max={labels1.length + 1}
                                        max={60}
                                        step={5}
                                        value={value1}
                                        className="custom-slider"
                                        handleStyle={handleStyle1}
                                        graduated
                                        progress
                                        onChange={(v) => setValue1(v)}
                                        renderMark={(mark) => {
                                            return mark + "x";
                                        }}
                                    />
                                </div> : <></>} </>}
                    {termsTab === "tp" &&
                        <div className="d-flex align-items-center justify-content-between pt-3"><h6 className="themeclr fw-500 mb-0">Market</h6>
                            <h6 className="themeclr fw-500 mb-0" onClick={() => setPoolnetmodal(true)} style={{ cursor: "pointer" }}> ETH-USD <MdKeyboardArrowDown /></h6>
                        </div>}
                    <div className="d-flex align-items-center justify-content-between pt-3"><h6 className="themeclr fw-500 mb-2">Pool</h6>
                        {/* <h6 className="themeclr fw-500 mb-2" onClick={() => setPoolnetmodal(true)} style={{ cursor: "pointer" }}>EDA-USD <MdKeyboardArrowDown /></h6> */}
                        <Dropdown className="themeclr drop_no">
                            <DropdownToggle className="themeclr fw-500">EDA-USD</DropdownToggle>
                            <DropdownMenu>
                                <Table responsive className="allpooltable">
                                    <thead>
                                        <tr>
                                            <th>POOL</th>
                                            <th>LONG LIQ</th>
                                            <th>NET RATE</th>
                                            <th>OPEN FEES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tablelists.map((e, i) =>
                                            <tr>
                                                <td>  <div className="d-flex poolgroup align-items-center"> <AvatarGroup max={2}>
                                                    <Avatar alt="Remy Sharp" src={Images.eth} style={{ width: "24px", height: "24px" }} />
                                                    <Avatar alt="Travis Howard" src={Images.dai} style={{ width: "24px", height: "24px" }} />
                                                </AvatarGroup>
                                                    <span className="themeclr fw-500">WETH-USDC</span>
                                                </div></td>
                                                <td>$ 12,768.00</td>
                                                <td className="redclr">-0.008% / 1hr</td>
                                                <td>-</td>
                                            </tr>)}
                                    </tbody>
                                </Table>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className="d-flex align-items-center bottom justify-content-between"><h6 className="themeclr fw-500">Collateral In</h6>
                        {/* <h6 className="themeclr fw-500" onClick={() => setPoolnetmodal(true)} style={{ cursor: "pointer" }}>EDA-USD <MdKeyboardArrowDown /></h6> */}
                        <Dropdown className="themeclr drop_no" show={isDropdownOpen} onToggle={() => setIsDropdownOpen(!isDropdownOpen)}>
                            <DropdownToggle className="themeclr fw-500" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>{selectcollateral.name}</DropdownToggle>
                            <DropdownMenu>
                                <h6 className="fw-600 mb-3">Collateral In</h6>
                                {collateral.map((e, i) =>
                                    <h6 className="themeclr mb-3 cursor" key={i}
                                        onClick={() => handleSelect(e)} ><img src={e.img} className="img-fluid ethimg" /> {e.name} </h6>
                                )}
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="d-flex align-items-center cursor justify-content-between cursor pt-3" onClick={() => setLimitopen(!limitopen)}>
                        <h6 className="themeclr fw-500 mb-0">Limit/TP/SL</h6>
                        {limitopen ? <MdKeyboardArrowUp className="arrowicon" /> : <MdKeyboardArrowDown className="arrowicon" />}
                    </div>
                    <div className={limitopen ? "d-block" : "d-none"}>
                        <div className="d-flex justify-content-between mt-2">
                            <h6 className="mb-0 themeclr">Take-Profit</h6>
                            
                            <div className="d-flex align-items-center flex-column gap-1">
                                {filterprofitRows.map((row, index) => (
                                    <div className="d-flex align-items-center flex-row gap-1 mb-1" key={index}>
                                        <div className="pos">
                                            <input type="text" placeholder="Price" className="form-control hegset text-end" /><span className="dollaricon fw-600">$</span>
                                        </div>
                                        <div className="position-relative" ref={listProfitRef}>
                                            <input type="text" placeholder="Size" className="form-control hegset1" onChange={(e) => handleprofitSelects(index, { per: e.target.value })} value={row.selectpercent.per} onClick={() => handleprofitInputClick(index)} /><span className="pericon fw-600">%</span>
                                        </div>
                                        <button className="btn btn-brand-1 hover-up py-1 px-1" onClick={addFiltersprofit} disabled={isAddprofitDisabled}><FaPlus /></button>
                                        <button className="btn btn-brand-1 hover-up py-1 px-1" disabled={filterprofitRows.length > 1 ? false : true} onClick={removeprofitFilters}> <MdClose /></button>
                                    </div>))}
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between pt-2">
                            <h6 className="mb-0 themeclr">Take-Profit PnL</h6>
                            <h6 className="mb-0">-</h6>
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                            <h6 className="mb-0 themeclr">Stop Loss</h6>

                            <div className="d-flex align-items-center flex-column gap-1">
                                {filterRows.map((row, index) => (
                                    <div className="d-flex align-items-center flex-row gap-1 mb-1" key={index}>
                                        <div className="pos">
                                            <input type="text" placeholder="Price" className="form-control hegset text-end" /><span className="dollaricon fw-600">$</span>
                                        </div>
                                        <div className="position-relative" ref={listContainerRef}>
                                            <input type="text" placeholder="Size" className="form-control hegset1" onChange={(e) => handleSelects(index, { per: e.target.value })} value={row.selectpercent.per} onClick={() => handleInputClick(index)} /><span className="pericon fw-600">%</span>
                                            {/* {row.isListVisible && (
                                                <ul className="listdata">
                                                    {percent.map((e, i) =>
                                                        <li className="cursor" key={i} onClick={() => handleSelects(index, e)}>{e.per}</li>)}
                                                </ul>
                                            )} */}
                                        </div>
                                        <button className="btn btn-brand-1 hover-up py-1 px-1" onClick={addFilters} disabled={isAddDisabled}><FaPlus /></button>
                                        <button className="btn btn-brand-1 hover-up py-1 px-1" disabled={filterRows.length > 1 ? false : true} onClick={removeFilters}> <MdClose /></button>
                                    </div>))}


                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between pt-2">
                            <h6 className="mb-0 themeclr">Stop-Loss PnL</h6>
                            <h6 className="mb-0">-</h6>
                        </div>
                    </div>




                    {termsTab != "tp" &&
                        <>
                            <div className="d-flex align-items-center justify-content-between pt-3"><h6 className="themeclr fw-500 mb-2">Leverage</h6><h6 className="themeclr fw-500 mb-2">2.00x</h6></div>
                            <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-2">Entry Price</h6><h6 className="themeclr fw-500 mb-2">$1.896</h6></div>
                            <div className="d-flex align-items-center bottom justify-content-between"><h6 className="themeclr fw-500">Liq. Price</h6><h6 className="themeclr fw-500">_</h6></div>
                            <div className="d-flex align-items-center justify-content-between pt-3"><h6 className="themeclr fw-500">Fees (Rebated) and Price Impact</h6><h6 className="themeclr fw-500">-$2.75</h6></div>
                        </>
                    }
                    {termsTab === "tp" &&
                        <>
                            <div className="d-flex align-items-center justify-content-between pt-3"><h6 className="themeclr fw-500 mb-2">Trigger Price</h6><h6 className="themeclr fw-500 mb-2">$0.00</h6></div>
                            <div className="d-flex align-items-center bottom justify-content-between"><h6 className="themeclr fw-500">Execution Price</h6><h6 className="themeclr fw-500">_</h6></div>
                            <div className="d-flex align-items-center justify-content-between pt-3"><h6 className="themeclr fw-500 mb-2">Fees and Price Impact</h6><h6 className="themeclr fw-500 mb-2">-</h6></div>
                            <div className="d-flex align-items-center justify-content-between">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                    </Tooltip>}
                                >
                                    <h6 className="themeclr fw-500 underline">Execution Price</h6></OverlayTrigger>
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                                    </Tooltip>}
                                >
                                    <h6 className="themeclr fw-500 underline">-$0.20</h6></OverlayTrigger></div>
                        </>

                    }


                    <button className="btn btn-brand-1 sitebtn hover-up w-100 mt-3">Connect Wallet</button>

                    <div className="fundclr rounded-4 mt-3">
                        <h5 className="blkclr bottom fw-600 py-3 px-3">{props.tab} EDA</h5>
                        <div className="px-3 py-2">
                            <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-3">Market</h6><h6 className="themeclr fw-500 mb-3">EDA/USD [EDA-USD]</h6></div>
                            <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-3">Entry Price</h6>
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">
                                        <div className="py-2 px-2">
                                            The position will be opened at a reference price of $2,982.63, not accounting for price impact, with a max slippage of -0.30%.
                                            <br /><br />
                                            The slippage amount can be configured under Settings, found by clicking on your address at the top right of the page after connecting your wallet.
                                        </div>
                                    </Tooltip>}
                                >
                                    <h6 className="themeclr fw-500 underline mb-3">-2.75</h6>
                                </OverlayTrigger>
                            </div>
                            <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-3">Exit Price</h6>
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">
                                        Simple tooltip
                                    </Tooltip>}
                                >
                                    <h6 className="themeclr fw-500 underline mb-3">-2.75</h6>
                                </OverlayTrigger>
                            </div>
                            <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-3">Borrow Fee</h6>
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">
                                        Simple tooltip
                                    </Tooltip>}
                                >
                                    <h6 className="themeclr fw-500 underline mb-3">-0.0052% / 1h</h6>
                                </OverlayTrigger>
                            </div>
                            <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-3">{props.tab == "Long" ? "Funding Fee" : "Funding Debate"}</h6>
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">
                                        Simple tooltip
                                    </Tooltip>}
                                >
                                    <h6 className="themeclr fw-500 underline mb-3">-0.0052% / 1h</h6>
                                </OverlayTrigger>
                            </div>
                            <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-3">Available Liquidity</h6>
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">
                                        Simple tooltip
                                    </Tooltip>}
                                >
                                    <h6 className="themeclr fw-500 underline mb-3">-0.0052% / 1h</h6>
                                </OverlayTrigger>
                            </div>
                            <div className="d-flex align-items-center justify-content-between"><h6 className="themeclr fw-500 mb-3">Open Interest Balance</h6>
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={<Tooltip id="button-tooltip">
                                        <div className="px-0 py-2">
                                            <div className="d-flex mb-3">
                                                <p className="mb-0">Long Open Interest</p>
                                                <div>
                                                    <p className="mb-0">$14,356</p>
                                                    <p className="mb-0">(23.3%)</p>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <p className="mb-0">Short Open Interest</p>
                                                <div>
                                                    <p className="mb-0">$14,356</p>
                                                    <p className="mb-0">(23.3%)</p>
                                                </div>
                                            </div></div>
                                    </Tooltip>}
                                >
                                    <p className="underline"><span className="redclr fw-500 me-2">3.87</span><span className="grnclr fw-500">3.87</span></p>
                                </OverlayTrigger>
                            </div>

                        </div>
                    </div>

                </div>



            </div >
        </>
    )
}

export default Longtab;