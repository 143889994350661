import React, { useEffect, useState } from "react";
import { Button, Container, Row, Dropdown } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";
import buyicon from "../Assets/navicon/buy-icon.png";
import favicons from "../Assets/favicon.png";
import dropleftimg from "../Assets/subpages/edadroplogo.png";
import lefdroplogo from "../Assets/subpages/leftdroplogo.png";
import worldimg from "../Assets/subpages/worldimg.png";
import profileicon from "../Assets/subpages/profileicon.png";
import downarrowss from "../Assets/downarrows.png";
import eng from "../Assets/navicon/eng.png";
import jap from "../Assets/navicon/jap.png";
import login from "../Assets/navicon/login.png";
import registration from "../Assets/navicon/registration.png";
import wallet from "../Assets/navicon/wallet.png";
import presale from "../Assets/navicon/presale.png";
import cointoken from "../Assets/navicon/cointoken.png";
import exchange from "../Assets/navicon/exchange.png";
import avalan from "../Assets/navicon/avalan.png";
import Walletpage from "../Components/Modals/walletmodal"
import { FaWallet } from "react-icons/fa";
import GTranslateWidget from "./gtranslate";

export default function Connectbutton() {

     const [Walletmodal, setWalletmodal] = useState(false);
     const [showDropdown, setShowDropdown] = useState(false);
     const [showDropdown1, setShowDropdown1] = useState(false);
     const [showDropdown2, setShowDropdown2] = useState(false);
     const [showDropdown3, setShowDropdown3] = useState(false);

     const [windowWidth, setWindowWidth] = useState(window.innerWidth);

     useEffect(() => {
       const handleWindowResize = () => {
         setWindowWidth(window.innerWidth);
       };
   
       window.addEventListener('resize', handleWindowResize);
   
       return () => {
         window.removeEventListener('resize', handleWindowResize);
       };
     });

    return (

        <>
            {Walletmodal && <Walletpage onDismiss={() => setWalletmodal(false)} />}

            <div>
              {localStorage.getItem("metamask") !== "true" ?
              <div className="textcenter"> 
                <div className="d-sm-inline-block mb-2 mb-md-0 ms-4 ms-md-0" onClick={() => { setWalletmodal(true) }}>
                    <Link className="btn btn-brand-1 hover-up">
                        {/* {windowWidth > 767 ? "Connect Wallet" : <FaWallet /> } */}
                        Connect Wallet
                        </Link>
                </div>
                </div>
                :
                <div className="">
                <div className="mobhead align-items-center justify-content-end">
                    <Dropdown
                        className="me-2"
                        onMouseLeave={() => setShowDropdown(false)}
                        onMouseOver={() => setShowDropdown(true)}>
                        <Dropdown.Toggle
                            className="main-style"
                            id="dropdown-basic">
                            <span className="themehoverclr ms-4">%EDA</span> <span className="themeclr">0.3561</span>
                            <img src={dropleftimg} className="img-fluid dropleftimg" alt="images" />
                            <img src={downarrowss} className="img-fluid ms-2 downarrowss" alt="images" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu show={showDropdown}>
                            <Link className="px-2" to="/"><img src={favicons} className="img-fluid me-0" alt="images" /> Buy EDA Coin</Link>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown
                        onMouseLeave={() => setShowDropdown1(false)}
                        onMouseOver={() => setShowDropdown1(true)}>
                        <Dropdown.Toggle
                            className="main-style me-2"
                            id="dropdown-basic">
                            <span className="themeclr">BNB Chain</span>
                            <img src={lefdroplogo} className="img-fluid leftdroplogo" alt="images" />
                            <img src={downarrowss} className="img-fluid downarrowss ms-2" alt="images" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu show={showDropdown1}>
                            <Link className="px-2 mt-3 mt-md-0" to="/"><img src={presale} className="img-fluid navimg me-1" alt="images" /> BNB Chain</Link>
                            <Link className="px-2 mt-3 mt-md-0" to="/"><img src={cointoken} className="img-fluid navimg me-1" alt="images" /> Arbitrum or ZK sync</Link>
                            <Link className="px-2 mt-3 mt-md-0" to="/"><img src={exchange} className="img-fluid navimg me-1" alt="images" /> Polygon Matics</Link>
                            <Link className="px-2 mt-3 mb-3 mb-md-0 mt-md-0" to="/"><img src={avalan} className="img-fluid navimg me-1" alt="images" /> Avalanche</Link>
                        </Dropdown.Menu>
                    </Dropdown>
                    {/* <Dropdown
                    className="me-0 ms-0 me-lg-2 ms-lg-2"
                        onMouseLeave={() => setShowDropdown2(false)}
                        onMouseOver={() => setShowDropdown2(true)}>
                        <Dropdown.Toggle
                            className="main-styless"
                            id="dropdown-basic">
                            <img src={worldimg} className="img-fluid worldimg worlds" alt="images" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu show={showDropdown2}>
                            <Link to="/" className="px-4 mt-3 mt-md-0"><img src={eng} className="img-fluid navimg me-1" alt="images" /> English</Link>
                            <Link to="/" className="px-4 mt-3 mb-3 mb-md-0 mt-md-0"><img src={jap} className="img-fluid navimg me-1" alt="images" /> Others</Link>
                        </Dropdown.Menu>
                    </Dropdown> */}
                    <Dropdown
                        className="ms-0 ms-md-2"
                        onMouseLeave={() => setShowDropdown3(false)}
                        onMouseOver={() => setShowDropdown3(true)}>
                        <Dropdown.Toggle
                            className="main-styless"
                            id="dropdown-basic">
                            <img src={profileicon} className="img-fluid worldimg profimages" alt="images" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu show={showDropdown3}>
                            <Link to="/login" className="px-4 mt-3 mt-md-0"><img src={login} className="img-fluid navimg me-1" alt="images" /> Login</Link>
                            <Link to="/login" className="px-4 mt-3 mt-md-0"><img src={registration} className="img-fluid navimg me-1" alt="images" /> Register</Link>
                            {/* <Link to="/"><img src={wallet} className="img-fluid navimg1 me-1" alt="images" /> Connect Wallet</Link> */}
                            <Link className="px-4 mt-3 mb-3 mb-md-0 mt-md-0"  onClick={()=>{localStorage.clear();window.location.reload()}}><img src={wallet} className="img-fluid navimg1 me-1" alt="images" /> Disconnect</Link>            
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                </div> 
            }
            </div>


        </>
    )
}

