import React, { useEffect, useState } from 'react';
import { Form, Table, InputGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import { FaSort } from "react-icons/fa";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoArrowUp } from "react-icons/io5";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function Progressprice(props) {

    const [progresstabs, setProgresstabs] = useState("alltab");
    const [listnumber, setListnumber] = useState([
        { id: 1, width: "20%" }, { id: 2, width: "32%" }, { id: 3, width: "14%" }, { id: 4, width: "10%" },
        { id: 5, width: "19%" }, { id: 6, width: "20%" }, { id: 7, width: "22%" }, { id: 8, width: "24%" },
        { id: 9, width: "20%" }, { id: 10, width: "34%" }, { id: 11, width: "15%" }, { id: 12, width: "39%" },
        { id: 13, width: "39%" },{ id: 14, width: "79%" },{ id: 15, width: "45%" },{ id: 15, width: "62%" },
        { id: 16, width: "62%" },{ id: 17, width: "62%" },{ id: 18, width: "62%" },{ id: 19, width: "62%" },
    ])

    const [width, setWidth] = useState(0);    

    useEffect(() => {
        const handleresize = () => {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleresize);
        return () => {
            window.removeEventListener("resize", handleresize);
        }
    }, []);


    return (
        <div>

            <div className='d-flex justify-content-between gap-2 align-items-center pb-3'>
                
                    <>
                    <ul className='d-flex gap-3 align-items-center allcolortab mb-0 pb-0'>
                    <li className={progresstabs == "alltab" ? "active" : ""} onClick={() => setProgresstabs("alltab")} ><img src={Images.tab1} className='img-fluid ctabimg' /></li>
                    <li className={progresstabs == "redtab" ? "active" : ""} onClick={() => setProgresstabs("redtab")} ><img src={Images.tab2} className='img-fluid ctabimg' /></li>
                    <li className={progresstabs == "greentab" ? "active" : ""} onClick={() => setProgresstabs("greentab")}><img src={Images.tab3} className='img-fluid ctabimg' /></li>
                    </ul>
                
                    <div className='d-flex'>
                    <Dropdown className='drop_no'>
                        <DropdownToggle>0.01</DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>0.01</DropdownItem>
                            <DropdownItem>0.1</DropdownItem>
                            <DropdownItem>1</DropdownItem>
                            <DropdownItem>10</DropdownItem>
                            <DropdownItem>50</DropdownItem>
                            <DropdownItem>100</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <Dropdown className='ms-3 three_dot'>
                        <DropdownToggle><HiOutlineDotsVertical /></DropdownToggle>
                        <DropdownMenu className='w300'>
                            <p className='themeclr fw-600 mb-2'>Order Book Display</p>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label class="form-check-label fw-500" for="flexCheckDefault">
                                    Display Avg.&Sum
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                <label class="form-check-label fw-500" for="flexCheckDefault">
                                    Show Buy/Sell Ratio
                                </label>
                            </div>
                            <p className='themeclr fw-600 mb-2 mt-3'>Book Depth Visualization</p>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="" id="flexCheckDefault" />
                                <label class="form-check-label fw-500" for="flexCheckDefault">
                                    Amount
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" value="" id="flexCheckDefault" />
                                <label class="form-check-label fw-500" for="flexCheckDefault">
                                    Cumlative
                                </label>
                            </div>
                        </DropdownMenu>
                    </Dropdown></div>
                    </>
            </div>
            
            <div className="price_header mb-2">
                <div class="contents">
                    <p className='mb-0'>Price</p>
                    <p className='mb-0'>Amount(BTC)</p>
                    <p className='mb-0'>Total</p>
                </div>
            </div>

                    {progresstabs != "greentab" &&
                        <>
                            {listnumber.map((e, i) =>
                              <OverlayTrigger
                              placement={width > 1199 ? "right" : "bottom" }
                              delay={{ show: 250, hide: 400 }}
                              overlay={<Tooltip id="button-tooltip" className='w300'>
                                 <div className='d-flex justify-content-between mt-1 mb-2'><p className='mb-0'>Avg Price</p><p className='mb-0'>0.051</p></div>
                                 <div className='d-flex justify-content-between mb-2'><p className='mb-0'>Sum BTC</p><p className='mb-0'>19,254</p></div>
                                 <div className='d-flex justify-content-between mb-2'><p className='mb-0'>Sum USDT</p><p className='mb-0'>62,014</p></div>
                              </Tooltip>}
                          >
                                <div className='price_change px-2 cursor hovertr'>
                                    <span className='width_set' style={{ background: "#f6465d", width: e.width }}></span>
                                    <p className='mb-0 redclr'>66678.00</p>
                                    <p className='mb-0'>0.07860</p>
                                    <p className='mb-0'>5,240.890</p>
                                </div>
                                </OverlayTrigger>
                                )}
                        </>
                }
                    {progresstabs != "redtab" &&
                        <div className='mt-2'>
                            <div className='d-flex justify-content-between px-2'><h5 className='grnclr'> 54,876.00 <IoArrowUp /></h5>  <p className='themeclr fw-500 mb-0'>More</p> </div>
                            {listnumber.map((e, i) =>
                              <OverlayTrigger
                              placement={width > 1199 ? "right" : "bottom" }
                              delay={{ show: 250, hide: 400 }}
                              overlay={<Tooltip id="button-tooltip" className='w300'>
                                 <div className='d-flex justify-content-between mt-1 mb-2'><p className='mb-0'>Avg Price</p><p className='mb-0'>0.051</p></div>
                                 <div className='d-flex justify-content-between mb-2'><p className='mb-0'>Sum BTC</p><p className='mb-0'>19,254</p></div>
                                 <div className='d-flex justify-content-between mb-2'><p className='mb-0'>Sum USDT</p><p className='mb-0'>62,014</p></div>
                              </Tooltip>}
                          >
                                <div className='price_change px-2 cursor hovertr'>
                                    <span className='width_set' style={{ background: "green", width: e.width }}></span>
                                    <p className='mb-0 grnclr'>66678.00</p>
                                    <p className='mb-0'>0.07860</p>
                                    <p className='mb-0'>5,240.890</p>
                                </div>
                                </OverlayTrigger>
                                )}
                        </div>}
             


        </div>
    )
}

export default Progressprice;