import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Table, InputGroup } from "react-bootstrap";
import Images from "../../Components/Images";
import { GoPlus } from "react-icons/go";
import { GoMail } from "react-icons/go";
import { Link } from "react-router-dom";

const Projectscore = () => {
    
    const scoretablehead = ["Total Score", "Percentage", "Investment Interpretation"];
    const [scoretable, setScoretable] = useState([
        {
            tscore: "0 – 25.0",
            percent: "0 – 20",
            invest: "Bad Investment"
        },
        {
            tscore: "25.1 - 50.0",
            percent: "21 – 40",
            invest: "Cautious Investment"
        },
        {
            tscore: "50.1 – 75.0",
            percent: "41 – 60",
            invest: "Good Investment"
        },
        {
            tscore: "75.1 – 100.0",
            percent: "61 – 80",
            invest: "Very Good Investment"
        },
        {
            tscore: "100.1 – 125.0",
            percent: "81 – 100",
            invest: "Awesome Investment"
        }
    ]);


    return (
        <>


<h6 className="h6">EdaFace SOC Score Interpretation</h6>
                                    <div className="tablebox">
                                        <div className="table-responsive">
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        {scoretablehead.map((th) => (
                                                            <th>{th}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {scoretable.map((td) => (
                                                        <tr>
                                                            <td>{td.tscore}</td>
                                                            <td>{td.percent}</td>
                                                            <td>{td.invest}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="notebox">
                                            <b>Note:</b>
                                            <p className="content noteblue mt-1 mb-3">
                                                For Failed Project (SOC score less than 30%):
                                            </p>
                                            <p className="content t-orange mb-3">
                                                Sorry, you cannot list yet on EdaFace. You’ll need to improve on your score to be able to list on  EdaFace.
                                            </p>
                                            <p className="content t-orange mb-3">
                                                Your Project must score at least 30% in order to list on EdaFace.
                                            </p>

                                            <p className="content t-orange">
                                                If you want to see the full analysis of your score that will show area of improvement, <Link to="/">click  here….</Link>
                                            </p>
                                           
                                        </div>                                      
                                    </div>

        </>


    )
}

export default Projectscore;