import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, Table, DropdownItem } from 'react-bootstrap';
import { FaSort } from "react-icons/fa";
import starimg from "../Assets/star.png"


function Symbolmodal() {

    const [datelist, setdatelist] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }
    ]);

    const [listdatas, setListdatas] = useState([
        { id: 1, coin:"USDC", symboltxt:"-2456-4600-C", symbolx:"2.96x",lp:"4,235", hr:"6.52%",vol:"6.791"}, 
        { id: 2, coin:"USDC", symboltxt:"-2456-4600-C", symbolx:"2.96x",lp:"4,235", hr:"6.52%",vol:"6.791"},
        { id: 3, coin:"USDC", symboltxt:"-2456-4600-C", symbolx:"2.96x",lp:"4,235", hr:"6.52%",vol:"6.791"},
        { id: 4, coin:"ETH", symboltxt:"-2456-4600-C", symbolx:"2.96x",lp:"4,235", hr:"6.52%",vol:"6.791"},
        { id: 5, coin:"BNB", symboltxt:"-2456-4600-C", symbolx:"2.96x",lp:"4,235", hr:"6.52%",vol:"6.791"},
        { id: 6, coin:"BNB", symboltxt:"-2456-4600-C", symbolx:"2.96x",lp:"4,235", hr:"6.52%",vol:"6.791"},
    ]);

    const [cointabs, setCointabs] = useState("USDC");
    const coins = ["USDC", "ETH", "BNB"];
    const [searchcoin, setSearchcoin] = useState("");
    const tablecontents = listdatas.filter((cl) => {
        if(searchcoin === "") {
            return cl;
        } else {
            return cl.pair.toLowerCase().includes(searchcoin);
        }
    });
    

    
    return (

        <div className='option_border px-2 py-3'>
            <div className='coinpairprice'>
                <div className='supplytable'>
                    <div className='d-flex justify-content-between mb-3'>
                        <ul className='listtab optioncoin d-flex align-items-center gap-3 mb-0'>
                            {coins.map((name)=>
                            <li 
                            className={cointabs === name  ? 'active fw-600':""}
                            onClick={()=>setCointabs(name)}>{name}</li>)}
                        </ul>
                        <Dropdown>
                            <DropdownToggle>All</DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem>All</DropdownItem>
                                <DropdownItem>Call</DropdownItem>
                                <DropdownItem>Put</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    <div className='d-flex gap-4 mb-3'>
                        <p className='orgclr mb-0 fw-600'>All</p>
                        <div className='d-flex gap-3 wauto overauto'>
                            {datelist.map((e, i) => <p className='mb-0'>2024-05-24</p>)}
                        </div>
                    </div>

                    <Table responsive hover className='pairpricetable'>
                        <thead>
                            <tr>
                                <th>Symbol <FaSort /></th>
                                <th>Last Price <FaSort /></th>
                                <th>24h% <FaSort /></th>
                                <th>Vol(USDT) <FaSort /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tablecontents.map((e, i) =>
                            <>
                              {e.coin == cointabs &&    
                                <tr>
                                    <td>
                                        <div>
                                            <p className='mb-1 blkclr fw-600'><img src={starimg} className='img-fluid' />{e.coin == cointabs && e.coin}-2456-4600-C</p>
                                            <p className='mb-0 orgclr fw-600 f-13'>{e.symbolx}</p>
                                        </div>
                                    </td>
                                    <td><p className='themeclr mb-0'>{e.lp}</p></td>
                                    <td><p className='themeclr mb-0'>{e.hr}</p></td>
                                    <td><p className='themeclr mb-0'>{e.vol}</p></td>

                                </tr>}
                             </>
                            )}
                        </tbody>

                    </Table>
                </div>

            </div></div>

    )
}

export default Symbolmodal