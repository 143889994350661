import React, { useState, useEffect } from 'react';
import Images from "../../Components/Images";
import { Table } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";


function Tableposition() {

    return (
        <div className="price_table w-100 overauto">
            <Table responsive>
                <thead>
                    <tr>
                        <th>Symbol</th>
                        <th>Size</th>
                        <th>Available</th>
                        <th>Average Price</th>
                        
                        <th>Unrealized PnL</th>
                        <th>ROE%</th>
                        <th>Market Value</th>
                        <th>Close Position</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>-</td>
                        <td className='grnclr fw-500'>-</td>
                        <td>-</td>
                        <td>-</td> 
                        <td className='grnclr fw-500'>-</td> 
                        <td>-</td>
                        <td>-</td>
                        <td className='d-flex gap-3 align-items-center'>-</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default Tableposition;