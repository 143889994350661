import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Table, InputGroup } from "react-bootstrap";
import Images from "../../Components/Images";
import { GoPlus } from "react-icons/go";
import { Link, useLocation } from "react-router-dom";
import { FiMinus } from "react-icons/fi";
import { GoMail } from "react-icons/go";


const Blockchainplatform = (props) => {

    const [filterRows2, setFilterRows2] = useState([{ filters: "" }]);
    const addFilters2 = () => {
        setFilterRows2([...filterRows2, { filters: "" }]);
    };
    const removeFilters2 = (index) => {
        const list = [...filterRows2]
        list.splice(index, 1)
        setFilterRows2(list)
    };


    return (

        <>

            {filterRows2.map((index, id) => (
                <>
                    <span className="d-block text-center content noteblue mt-4 mb-2">
                        <b>Blockchain Platform </b>
                    </span>
                    <Form>
                        <Form.Label>Blockchain name<span className="text-danger">*</span></Form.Label>
                        <InputGroup className="mb-4">
                            <InputGroup.Text><GoMail /></InputGroup.Text>
                            <Form.Control type="text" placeholder="Blockchain Name"></Form.Control>
                        </InputGroup>
                        <Form.Label>Blockchain explorer link<span className="text-danger">*</span></Form.Label>
                        <InputGroup className="mb-4">
                            <InputGroup.Text><GoMail /></InputGroup.Text>
                            <Form.Control type="text" placeholder="Blockchain Link"></Form.Control>
                        </InputGroup>
                        <Form.Label>Contract address</Form.Label>
                        <InputGroup className="topinputgroup align-items-start">
                            <InputGroup.Text><GoMail /></InputGroup.Text>
                            <Form.Control as="textarea" placeholder="Enter your address...."></Form.Control>
                        </InputGroup>
                    </Form>
                </>))}
            <span className="d-inline-flex float-end pb-4">
                <span className="add me-2" onClick={removeFilters2}><FiMinus /></span>
                <span className="add" onClick={addFilters2}><GoPlus /></span>
            </span>

        </>

    )
}

export default Blockchainplatform;