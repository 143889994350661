import React, { useState, useEffect } from "react";
import { Modal, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Images from "../../Components/Images";
import { IoClose } from "react-icons/io5";
import { Slider } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';


function Leveragetarget(props) {

  const [synleveragemodal, setSynleveragemodal] = useState(true);
  const [valueslidess, setValueslidess] = useState(0);
  const handleSliderChange = (newValue) => {
    setValueslidess(newValue / 10);  // Adjust the division factor as needed
  };

  return (
    <>
      <div className='walletmodal'>

        <Modal show={synleveragemodal} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
          <Modal.Body className="py-4 px-3">
            <div className="text-end mb-2"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>
            <h5 className="themeclr">Adjust Leverage Target</h5>
            <p>Target leverage determines the amount of margin applied to your next order. Adjusting target leverage will not transfer margin until a new order is placed.</p>

            <div className="swap__blueBox p-2 p-sm-2 rounded-3 mt-2">
              <div className="d-flex align-items-center justify-content-between">
                <div className="w-100">
                  <h6 className="themeclr fw-600 mb-3">Target Leverage</h6>
                  <Slider
                    className="mb-2"
                    tooltip={false}
                    min={0}
                    max={200}
                    progress
                    value={valueslidess * 10}
                    onChange={handleSliderChange}
                  />
                </div>
                <div class="d-flex align-items-center group_input">
                  <input type="text" class="form-control swap_input slide_input text-end ps-0 pe-1" placeholder="0.00" onChange={(e) => {setValueslidess( Number(e.target.value)>20 ?  "20" :  e.target.value)}} value={valueslidess} inputmode="numeric"/>
                  <div class="input-group-append">
                    <span class="input-group-text ps-0">X</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between nox mt-3 px-1">
              <button className="btn btn-brand-1 hover-up" onClick={()=>setValueslidess(1*1)}>1x</button>
              <button className="btn btn-brand-1 hover-up" onClick={()=>setValueslidess(2*1)}>2x</button>
              <button className="btn btn-brand-1 hover-up" onClick={()=>setValueslidess(3*1)}>3x</button>
              <button className="btn btn-brand-1 hover-up" onClick={()=>setValueslidess(5*1)}>5x</button>
              <button className="btn btn-brand-1 hover-up" onClick={()=>setValueslidess(10*1)}>10x</button>
            </div>

            <div className="fundclr rounded-4 mt-3 px-3 py-3 mt-2">
              <div class="d-flex align-items-center justify-content-between">
                <h6 class="themeclr fw-500 mb-1">Target Leverage</h6>
                <h6 class="themeclr fw-500 mb-1">1.00× → {valueslidess}×</h6>
              </div>
            </div>

            <button class="btn btn-brand-1 sitebtn hover-up w-100 mt-3">Confirm Leverage</button>

          </Modal.Body>



        </Modal>
      </div>

    </>
  )
}

export default Leveragetarget;