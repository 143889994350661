import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Accordion, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaArrowTrendUp } from "react-icons/fa6";
import { TbArrowsLeftRight } from "react-icons/tb";
import Longtab from "../Components/longtab";
import Swaptab from "../Components/swaptab";
import Positiontable from "../Components/tables/positiontable";
import Ordertable from "../Components/tables/ordertable";
import Tradetable from "../Components/tables/tradetable";
import Claimtable from "../Components/tables/claimtable";
import Tradingview from "../Components/Chartss/tradingview";
import { TickerTape, AdvancedChart, TechnicalAnalysis, CompanyProfile } from "react-tradingview-embed";
import Announcement from "../Components/announcement";
import Tableposition from "../Components/Synthetics/table_position";
import Swal from 'sweetalert2';

function Trade() {

    const [selectnetwork, setSelectnetwork] = useState({ name: "EDA / USD" });
    const [networklist, setNetworklist] = useState([
        { name: "EDA / USD" }, { name: "EDA / ETH" }, { name: "EDA / BAL" }
    ]);
    const [activeTab, setActiveTab] = useState('long');
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const [activetableTab, setActivetableTab] = useState('position');
    const handleTableClick = (tab) => {
        setActivetableTab(tab);
    };
    useEffect(() => {
        Swal.fire({
            title: "Welcome to Spot Trading!",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
          });
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    return (

        <div>
            <Innerheader />
            <Container fluid className="innerpage pools_sec assetpage px-0 pos">
                <Container className="container_max pt-4">
                    <img src={Images.backlogo} className='backlogo farms__backlogo' />
                    {/* <Announcement /> */}
                    <h3 className="themeclr fw-600 text-center mb-4">Spot Trading</h3>
                    <div className="tradeall">
                        <div className="trade1">
                        <div className="row">
                                <div className="col-sm-9 col-md-9">
                                    <div className="netborder py-2 px-3">
                                        <div className="d-block d-sm-flex">
                                            
                                            <Dropdown className="market webcenter">
                                                <DropdownToggle className="ps-0 d-flex"><img src={Images.dexlogo} className="img-fluid me-2" /><h4 className="blkclr mb-0">{selectnetwork.name}<MdKeyboardArrowDown /></h4></DropdownToggle>
                                                <DropdownMenu>
                                                    {networklist.map((e, i) =>
                                                        <DropdownItem onClick={() => setSelectnetwork(e)}>{e.name}</DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </Dropdown>
                                            
                                            <div className="d-flex gap-4 overauto ms-2 mt-3 mt-sm-0">
                                                <div>
                                                    <h6 className="fw-600">$1.255</h6>
                                                    <h6 className="themeclr fw-400 mb-0">$1.255</h6>
                                                </div>
                                                <div>
                                                    <h6 className="themeclr fw-600">24 th change</h6>
                                                    <h6 className="themeclr fw-400 mb-0">-3.93%</h6>
                                                </div>
                                                <div>
                                                    <h6 className="themeclr fw-600">24 th High</h6>
                                                    <h6 className="themeclr fw-400 mb-0">1.289</h6>
                                                </div>
                                                <div>
                                                    <h6 className="themeclr fw-600">24 th Low</h6>
                                                    <h6 className="themeclr fw-400 mb-0">1.225</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-md-3">
                                    <div className="netborder py-2 px-2 mt-3 mt-sm-0">
                                        <div className="d-flex justify-content-center">
                                            <h6 className="vround themeclr fw-600 me-2">v1</h6>
                                            <h6 className="vround themeclr fw-600">v2</h6>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="trade2">
                            <div className="pos mt-1 mb-2 gridheight">
                                <Tradingview />
                                {/* <AdvancedChart widgetProps={{"theme": "light"}} /> */}
                            </div>
                        </div>
             
                        <div className="trade4">
                            <div className="longbor pos d-flex justify-content-between">
                                <h6 className={activeTab === "long" ? 'fw-500 longtab active mb-0' : 'fw-500 longtab mb-0'} onClick={() => handleTabClick('long')}><FaArrowTrendUp className="me-1" /> Long</h6>
                                <h6 className={activeTab === "short" ? 'fw-500 longtab active mb-0' : 'fw-500 longtab mb-0'} onClick={() => handleTabClick('short')}><FaArrowTrendUp className="me-1"/> Short</h6>
                                <h6 className={activeTab === "swap" ? 'fw-500 longtab active mb-0' : 'fw-500 longtab mb-0'} onClick={() => handleTabClick('swap')}><TbArrowsLeftRight className="me-1"/> Swap</h6>
                            </div>

                            {activeTab === "long" &&
                                <div>
                                    <Longtab tab={"Long"} />
                                </div>}
                            {activeTab === "short" &&
                                <div>
                                    <Longtab tab={"Short"} />
                                </div>}
                            {activeTab === "swap" &&
                                <div>
                                    <Swaptab />
                                </div>}
                        </div>

                        <div className="trade3">
                        <div className="netborder mt-1">
                            <ul className="table_tabs my-3">
                                <li className={activetableTab === "position" ? "active" : ""} onClick={() => handleTableClick('position')}>Position</li>
                                <li className={activetableTab === "orderid" ? "active" : ""} onClick={() => handleTableClick('orderid')}>Order Id</li>
                                <li className={activetableTab === "trades" ? "active" : ""} onClick={() => handleTableClick('trades')}>Trades</li>
                                <li className={activetableTab === "claims" ? "active" : ""} onClick={() => handleTableClick('claims')}>Claims</li>
                            </ul>
                            <div>
                                {activetableTab === "position" &&
                                    <>
                                        <Positiontable />
                                    </>
                                }
                                {activetableTab === "orderid" &&
                                   
                                        <Ordertable />
                                   
                                }
                                {activetableTab === "trades" &&
                                    <>
                                        <Tradetable />
                                    </>
                                }
                                {activetableTab === "claims" &&
                                    <>
                                        <Claimtable />
                                    </>
                                }
                            </div>

                        </div>
                        </div>

                     
                    </div>

                </Container>
            </Container>

            <Footer />
        </div>
    )
}

export default Trade;