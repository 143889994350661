import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Table, InputGroup } from "react-bootstrap";
import Images from "../../Components/Images";
import { GoPlus } from "react-icons/go";
import { GoMail } from "react-icons/go";



const Websiteurl = () => {

    return (
        <>
            <Form>
                <Form.Label>Website URL (1)<span className="text-danger">*</span></Form.Label>
                <InputGroup className="mb-4">
                    <InputGroup.Text><GoMail /></InputGroup.Text>
                    <Form.Control type="text" placeholder="Website url"></Form.Control>
                </InputGroup>
                <Form.Label>Website URL (2)</Form.Label>
                <InputGroup className="mb-4">
                    <InputGroup.Text><GoMail /></InputGroup.Text>
                    <Form.Control type="text" placeholder="Website url"></Form.Control>
                </InputGroup>
                <Form.Label>Website URL (3) </Form.Label>
                <InputGroup className="mb-4">
                    <InputGroup.Text><GoMail /></InputGroup.Text>
                    <Form.Control type="text" placeholder="Website url"></Form.Control>
                </InputGroup>
            </Form>
        </>

)
}

export default Websiteurl;