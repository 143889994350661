import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Col, Container, Row, InputGroup, Form, Accordion, Table } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import Announcement from "../Components/announcement";


function Ponds() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    return (
        <>
            <Innerheader />
            <div className="earnpage">
                <img src={Images.backlogo} className="backlogo farms__backlogo" />
                <div className="swap__page position-relative pb-5">
                    
                    <Container>
                    {/* <Announcement/> */}
                        <Row className="pt-4">
                            <Col xs={12} sm={12} md={12} lg={7}>
                                <h1 className="themeclr fw-600">Ponds</h1>
                                <p className="bannercontent">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting
                                </p>
                            </Col>
                        </Row>

                        <div className="p2ptrade">
                            <div className="p2ptable">
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Heading 1</th>
                                            <th>Heading 2</th>
                                            <th>Heading 3</th>
                                            <th>Heading 4</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                        </tr>
                                        <tr>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                        </tr>
                                        <tr>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                        </tr>
                                        <tr>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                            <td>Lorem Ipsum</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>


                    </Container>





                </div>
            </div>
            <Footer />
        </>
    )
}

export default Ponds