import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Accordion, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import { MdKeyboardArrowDown } from "react-icons/md";
import Spottab from "../Components/spottab";
import Gridtab from "../Components/gridtab";
import Switch from "react-switch";
import { HiTicket } from "react-icons/hi2";
import CoinPairPrice from "./CoinPairPrice";
import Tradingview from "../Components/Chartss/tradingview";
import Progressprice from "../Components/progressprice";
import Openorder from "../Components/Futuretable/openorder";
import Orderhistory from "../Components/Futuretable/orderhistory";
import Funds from "../Components/Futuretable/funds";
import Positions from "../Components/Futuretable/position";
import Announcement from "../Components/announcement";
import Swal from 'sweetalert2';

function Margin() {

    const [selectnetwork, setSelectnetwork] = useState({ name: "EDA / USD" });
    const [networklist, setNetworklist] = useState([
        { name: "EDA / USD" }, { name: "EDA / ETH" }, { name: "EDA / BAL" }
    ]);
    const [spotactives, setSpotactive] = useState("spot");
    const handlespot = (tab) => {
        setSpotactive(tab)
    };
    const [checked, setChecked] = useState(true);
    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };
    const [futurehistorytab, setFuturehistorytab] = useState("openorders")
    useEffect(() => {
        Swal.fire({
            title: "Welcome to Future Trading!",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
          });
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    return (
        <>
            <div>
                <Innerheader />
                <Container fluid className="innerpage pools_sec assetpage px-0 pos">
     
                    <Container className="pt-0 container_max">
                    <h3 className="themeclr fw-600 text-center mt-3 mb-3">Future Trading</h3>
                    {/* <Announcement/> */}
                        <img src={Images.backlogo} className='backlogo farms__backlogo' />

                        <div className="option_border py-3 px-2">
                            <div className="margin_all">

                                <div className="netborder grid_margin1">
                                    <div className="flexbox">
                                        <div>
                                            <Dropdown className="market ms-sm-2">
                                                <DropdownToggle className="ps-0 d-flex align-items-center"><img src={Images.dexlogo} className="img-fluid me-2" /><h5 className="blkclr mb-0">{selectnetwork.name}<MdKeyboardArrowDown /></h5></DropdownToggle>
                                                <DropdownMenu>
                                                    {networklist.map((e, i) =>
                                                        <DropdownItem onClick={() => setSelectnetwork(e)}>{e.name}</DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                        <div className="marginlisttop">
                                            <div className="d-flex marginlist align-items-center py-2 px-2">
                                                <div><p className="mb-1 blkclr f-14 fw-500">27,582.07</p><p className="mb-0 grnclr f-14 fw-500">27,582.07</p></div>
                                                <div><p className="mb-1 blkclr f-14 fw-500">24h Change</p><p className="mb-0 themeclr f-14 fw-400">27,582.07</p></div>
                                                <div><p className="mb-1 blkclr f-14 fw-500">24h High</p><p className="mb-0 redclr f-14 fw-400">$27,582.07</p></div>
                                                <div><p className="mb-1 blkclr f-14 fw-500">24h Low</p><p className="mb-0 redclr f-14 fw-400">$27,582.07</p></div>
                                                <div><p className="mb-1 blkclr f-14 fw-500">24h Volume(BTC)</p><p className="mb-0 f-14 redclr fw-400">$27,582.07</p></div>
                                                <div><p className="mb-1 blkclr f-14 fw-500">24h Volume(USDT)</p><p className="mb-0 f-14 redclr fw-400">$27,582.07</p></div>
                                            </div>
                                        </div>
                                        <div>
                                            <Dropdown className="trans me-sm-2 text-end text-sm-start">
                                                <DropdownToggle className="p-0" ><img src={Images.settings} className="img-fluid" /></DropdownToggle>
                                                <DropdownMenu>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </div>

                                </div>

                                <div className="pos grid_margin2">
                                    <Tradingview />
                                    {/* <AdvancedChart widgetProps={{"theme": "light"}} /> */}
                                </div>

                                <div className="netborder grid_margin3">
                                    <div className="d-block d-lg-flex justify-content-between align-items-center">
                                        <ul className="table_tabs overauto mb-0">
                                            <li className={spotactives === "spot" ? "active ms-0" : "ms-0"} onClick={() => handlespot("spot")}>Spot</li>
                                            <li className={spotactives === "cross" ? "active" : ""} onClick={() => handlespot("cross")}>Cross 5X</li>
                                            <li className={spotactives === "isolated" ? "active" : ""} onClick={() => handlespot("isolated")}>Isolated</li>
                                            <li className={spotactives === "grid" ? "active" : ""} onClick={() => handlespot("grid")}>Grid</li>
                                        </ul>
                                        {spotactives != "grid" &&
                                            <Dropdown className="text-end">
                                                <DropdownToggle className="px-2 py-1" >Fee level</DropdownToggle>
                                                <DropdownMenu className="w300">
                                                    <p className="fw-600 themeclr">Your Trading Fee levels</p>
                                                    <h6 className="blclr fw-600">BTC/USDT</h6>
                                                    <div className="d-flex justify-content-between pt-1">
                                                        <p className="fw-500 blkclr f-14 mb-1">Using BNB for fees <span className="themeclr">25% discount</span></p>
                                                        <Switch
                                                            onChange={handleChange}
                                                            checked={checked}
                                                            offColor="#162019"
                                                            onColor="#1888b7"
                                                            offHandleColor="#ffff"
                                                            onHandleColor="#00e7fa"
                                                            height={20}
                                                            width={36}
                                                            handleDiameter={18}
                                                            uncheckedIcon={false}
                                                            checkedIcon={false}
                                                            className="react-switch"
                                                            id="material-switch"
                                                        />
                                                    </div>
                                                    <p className="f-14 mb-1">No BNB Available</p>
                                                    <hr></hr>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div><p className="themeclr mb-0 fw-600">0.043% <span className="textcross fw-400">0.1%</span></p><p className="fw-500 mb-0">Taker</p></div>
                                                        <div><p className="themeclr mb-0 fw-600">0.043% <span className="textcross fw-400">0.1%</span></p><p className="fw-500 mb-0">Maker</p></div>
                                                    </div>

                                                    <p className="orgclr f-15 fw-500 mb-0 pt-2"><HiTicket className="mts" /> Invite Friends and Unlock Fee-Free Trading!</p>
                                                </DropdownMenu>
                                            </Dropdown>}
                                    </div>

                                    <div>
                                        {spotactives === "spot" &&
                                            <>
                                                <Spottab tab={"spot"} />
                                            </>
                                        }
                                        {spotactives === "cross" &&
                                            <>
                                                <Spottab tab={"cross"} />
                                            </>
                                        }
                                        {spotactives === "isolated" &&
                                            <>
                                                <Spottab tab={"isolated"} />
                                            </>
                                        }
                                        {spotactives === "grid" &&
                                            <>
                                                <Gridtab />
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="grid_margin4">
                                    <Progressprice />
                                </div>
                                <div className="grid_margin5">
                                    <CoinPairPrice cointab="cointabs" />
                                </div>
                                <div className="grid_margin6">
                                    <CoinPairPrice mtrade="markettrades" />
                                </div>
                            </div>

                        </div>


                        <div className="mt-3">
                            <div className='netborder py-3'>
                                <div className='d-block d-xl-flex justify-content-between align-items-center'>
                                    <ul className='table_tabs overauto position mt-1 mb-2 mb-xl-0 mx-2 mx-lg-0'>
                                        <li className={futurehistorytab === "openorders" ? "active" : ""} onClick={() => setFuturehistorytab("openorders")}>Open Orders(0)</li>
                                        <li className={futurehistorytab === "orderhistory" ? "active" : ""} onClick={() => setFuturehistorytab("orderhistory")}>Order History</li>
                                        <li className={futurehistorytab === "tradehistory" ? "active" : ""} onClick={() => setFuturehistorytab("tradehistory")}>Trade History</li>
                                        <li className={futurehistorytab === "funds" ? "active" : ""} onClick={() => setFuturehistorytab("funds")}>Funds</li>
                                        <li className={futurehistorytab === "positions" ? "active" : ""} onClick={() => setFuturehistorytab("positions")}>Positions(0)</li>
                                    </ul>
                                    <div class="form-check hidecheck flright pe-3"><input class="form-check-input" type="checkbox" id="flexCheckDefault" value="" /><label class="form-check-label fw-500" for="flexCheckDefault">Hide other pairs</label></div>
                                </div>

                                <div>
                                    {futurehistorytab === "openorders" &&
                                         <div className="mx-2">
                                          <Openorder/>
                                        </div>
                                    }
                                     {futurehistorytab === "orderhistory" &&
                                         <div className="mx-2">
                                         <Orderhistory/>
                                        </div>
                                    }
                                        {futurehistorytab === "tradehistory" &&
                                         <div className="mx-2">
                                         <Orderhistory/>
                                        </div>
                                    }
                                        {futurehistorytab === "funds" &&
                                         <div className="mx-2">
                                        <Funds/>
                                        </div>
                                    }
                                        {futurehistorytab === "positions" &&
                                         <div className="mx-2">
                                         <Positions/>
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>


                    </Container>
                </Container>

                <Footer />
            </div>
        </>
    )
}

export default Margin;
