import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import Header from "../../Layouts/header";
import Footer from "../../Layouts/footer";
import Images from '../Images';
import { Link, NavLink } from "react-router-dom";
import Innerheader from '../../Layouts/innerheader';


const Dashboard = () => {
 useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);


    return (
        <>

            <div>
                <Innerheader/>

                <Container fluid className="innerpage pools_sec assetpage px-0 pos">
                <Container className="pt-1 px-xxl-3">
                <h3 class="themeclr fw-600 text-center mt-4 mb-3">Dashboard</h3>
                <img src={Images.backlogo} className="backlogo farms__backlogo" />
                 <div className='dashboard pt-3 pt-md-3'>
                 <Row>
                    <Col md={6} lg={6} xl={4} xxl={4}>
                     <div className='swap__blueBox rounded-3 py-3 px-3 mt-4'>
                        <h5 className='themeclr'>Total Volume Locked</h5>
                        <p className='mb-0'>23,000</p>
                     </div>
                    </Col>
                    <Col md={6} lg={6} xl={4} xxl={4}>
                     <div className='swap__blueBox rounded-3 py-3 px-3 mt-4'>
                        <h5 className='themeclr'>Spot Trading Summary</h5>
                        <p className='mb-0'>1290</p>
                     </div>
                    </Col>
                    <Col md={6} lg={6} xl={4} xxl={4}>
                     <div className='swap__blueBox rounded-3 py-3 px-3 mt-4'>
                        <h5 className='themeclr'>Futures Perpetual Trading Summary</h5>
                        <p className='mb-0'>8700</p>
                     </div>
                    </Col>
                    <Col md={6} lg={6} xl={4} xxl={4}>
                     <div className='swap__blueBox rounded-3 py-3 px-3 mt-4'>
                        <h5 className='themeclr'>Options Trading Summary</h5>
                        <p className='mb-0'>6490</p>
                     </div>
                    </Col>
                    <Col md={6} lg={6} xl={4} xxl={4}>
                     <div className='swap__blueBox rounded-3 py-3 px-3 mt-4'>
                        <h5 className='themeclr'>Synthetic Trading Summary</h5>
                        <p className='mb-0'>11756</p>
                     </div>
                    </Col>
                 </Row>
                 </div>
                </Container>
                </Container>
                <Footer />
            </div>

        </>
    )
}

export default Dashboard;