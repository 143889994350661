import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Table } from "react-bootstrap";
import Images from "../../Components/Images";
import { GoPlus } from "react-icons/go";
import { Link, useLocation } from "react-router-dom";


const Liquidity = (props) => {

    const [yes, setYes] = useState(false);
    const location = useLocation();
    console.log(location, "asasda");

    return (

        <>

            <div className="text-center">
                <h6 className="formhead">Liquidity Supply</h6>
                <p className="content">
                    Please, indicate your project’s website. If you have more than one website, Website URL (1) should  be the Primary Website.
                </p>
            </div>
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>Supplied Liquidity (1)</Form.Label>
                    <Form.Control type="text" placeholder="How much liquidity was supplied"></Form.Control>
                    <Form.Control type="text" className="mt-2" placeholder="provide url"></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Supplied Liquidity (2)</Form.Label>
                    <Form.Control type="text" placeholder="How much liquidity was supplied"></Form.Control>
                    <Form.Control type="text" className="mt-2" placeholder="provide url"></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Supplied Liquidity (3)</Form.Label>
                    <Form.Control type="text" placeholder="How much liquidity was supplied"></Form.Control>
                    <Form.Control type="text" className="mt-2" placeholder="provide url"></Form.Control>
                </Form.Group>
                {props.path == "/cointoken" &&
                    <>
                        <Form.Group className="mb-3">
                            <Form.Label>Total Liquidity</Form.Label>
                            <Form.Control type="text" placeholder="Total Liquidity"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Total Liquidity Percentage</Form.Label>
                            <Form.Control type="text" placeholder="Total Liquidity Percentage"></Form.Control>
                        </Form.Group></>
                }
                {props.path == "/exchange" &&
                    <>
                        <Form.Group className="mb-3">
                            <Form.Label>Average Liquidity </Form.Label>
                            <Form.Control type="text" placeholder="Average Liquidity "></Form.Control>
                        </Form.Group>
                    </>
                }
                <Form.Group className="mb-3">
                    <Form.Label>Time-Lock<span className="text-danger">*</span></Form.Label>
                    <Form.Control type="text" placeholder="time-lock"></Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label className="mb-3">Any time-lock in contract<span className="text-danger">*</span></Form.Label>
                    <div className="flexbox justify-content-between">
                        <Form.Check type="checkbox" label="Yes" onClick={() => setYes(!yes)}></Form.Check>
                        <Form.Check type="checkbox" label="No"></Form.Check>
                        <Form.Check type="checkbox" label="Not Applicable"></Form.Check>
                    </div>
                </Form.Group>
                {
                    yes == true &&
                    <Form.Group className="mb-3">
                        <Form.Label>Provide url</Form.Label>
                        <Form.Control type="text" placeholder="Lorem ipsum"></Form.Control>
                    </Form.Group>
                }
                {props.path == "/exchange" &&
                    <>
                        <Form.Group className="mb-4">
                            <Form.Label>Service Fees </Form.Label>
                            <Form.Select className="form-control">
                                <option value="1">Maker Fee </option>
                                <option value="2">Taker Fee </option>
                                <option value="3">Other Fee</option>
                            </Form.Select>
                        </Form.Group>
                    </>
                }
            </Form>



        </>
    )
}

export default Liquidity;