import React, { useState, useEffect } from "react";
import { Modal, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Images from "../../Components/Images";
import { IoClose } from "react-icons/io5";


function Transfermodals(props) {

    const [syntransfermodal, setSyntransfermodal] = useState(true);

    return (
        <>
            <div className='walletmodal'>

                <Modal show={syntransfermodal} size="md" aria-labelledby="contained-modal-title-vcenter" centered >
                    <Modal.Body className="py-4 px-3">
                        <div className="text-end mb-2"><button className='btn close me-0' onClick={() => props.onDismiss()}><IoClose /></button></div>
                        <h5 className="themeclr pb-3">Transfer</h5>

                        <div className="d-flex gap-2">
                            <div className="swap__blueBox p-2 p-sm-2 rounded-3 w-100">
                                <h6 className="themeclr fw-600 mb-0">Destination</h6>
                                <input type="text" className="form-control swap_input ps-0" placeholder="Address" />
                            </div>
                            <div className="swap__blueBox p-2 p-sm-2 rounded-3 w-100">
                                <h6 className="themeclr fw-600 mb-0">Network</h6>
                                <p className="themeclr mb-0 mt-2 fw-500"><img src={Images.bnb} className="ethimg" /> BNB Chain</p>
                            </div>
                        </div>
                        <div className="swap__blueBox p-2 p-sm-2 rounded-3 w-100 mt-2">
                            <h6 className="themeclr fw-600 mb-2">Assets</h6>
                            <Dropdown className="no_drop dest_drop">
                                <DropdownToggle><img src={Images.usdc} className="ethimg" /> USDC</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem><img src={Images.usdc} className="ethimg" /> USDC</DropdownItem>
                                    <DropdownItem><img src={Images.bnb} className="ethimg" /> BNB</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>

                        <div className="swap__blueBox p-2 p-sm-2 rounded-3 w-100 mt-2">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="">
                                    <h6 className="themeclr fw-600 mb-0">Amount</h6>
                                    <input type="text" className="form-control swap_input ps-0" placeholder="0.000" />
                                </div>
                            </div>
                        </div>

                        <div className="rounded-3 py-2 px-2 mt-1">
                            <div class="d-flex align-items-center justify-content-between">
                                <h6 class="themeclr fw-500 mb-2">Available (USDC)</h6><h6 class="themeclr fw-500  mb-2">0.000</h6></div>
                        </div>

                        <div className="fundclr rounded-3 py-2 px-2 mt-1">
                            <div class="d-flex align-items-center justify-content-between">
                                <h6 class="themeclr fw-500 mb-3">Fees (USDC)</h6><h6 class="themeclr fw-500  mb-3">-</h6></div>
                            <div class="d-flex align-items-center justify-content-between">
                                <h6 class="themeclr fw-500 mb-3">Balance (USDC)</h6><h6 class="themeclr fw-500  mb-3">-</h6></div>
                            <div class="d-flex align-items-center justify-content-between">
                                <h6 class="themeclr fw-500 mb-3">Leverage</h6><h6 class="themeclr fw-500  mb-3">-</h6></div>
                        </div>

                        <button class="btn btn-brand-1 sitebtn hover-up w-100 mt-3">Confirm Transfer</button>

                    </Modal.Body>



                </Modal>
            </div>
        </>
    )
}

export default Transfermodals