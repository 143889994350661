import React, { useState, useEffect } from "react";
import { Form, Table, InputGroup } from "react-bootstrap";
import "../../Karthick.css";
import "../../inner.css";
import Images from "../../Components/Images";
import { FaSort } from "react-icons/fa";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


const Syntheticstrade = (props) => {

    const [listnumber, setListnumber] = useState([
        { id: 1, width: "20%", bg: "#f6465d", clr: "red" }, { id: 2, width: "32%", bg: "green", clr: "green" }, { id: 3, width: "14%", bg: "#f6465d", clr: "red" }, { id: 4, width: "10%", bg: "#f6465d", clr: "red" },
        { id: 5, width: "19%", bg: "green", clr: "green" }, { id: 6, width: "20%", bg: "green", clr: "green" }, { id: 7, width: "22%", bg: "#f6465d", clr: "red" }, { id: 8, width: "24%", bg: "#f6465d", clr: "red" },
        { id: 9, width: "20%", bg: "#f6465d", clr: "red" }, { id: 10, width: "34%", bg: "green", clr: "green" }, { id: 11, width: "15%", bg: "green", clr: "green" },
    ]);

    return (
        <>
            {/* <h6 className="mb-3 fw-600">My Trades</h6> */}
            <div>
                <div className="price_header mx-0">
                    <div class="contents mb-3">
                        <p className='mb-0 themeclr fw-500'>Size(ETH) </p>
                        <p className='mb-0 themeclr fw-500'>Price(USDT)</p>
                        <p className='mb-0 themeclr fw-500'>Time</p>
                    </div>
                </div>
                {listnumber.map((e, i) =>
                    <div className='price_change px-2'>
                        <span className='width_set' style={{ background: e.bg, width: e.width }}></span>
                        <p className='mb-0' style={{ color: e.clr }}>0.706</p>
                        <p className='mb-0'>$4298.7</p>
                        <p className='mb-0'>12.20.24</p>
                    </div>)}
            </div>
        </>
    )
}

export default Syntheticstrade;