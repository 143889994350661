import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Table } from "react-bootstrap";
import Images from "../../Components/Images";
import { GoPlus } from "react-icons/go";
import { Link } from "react-router-dom";


const Projectteam = () => {

    const [yessix, setYessix] = useState(true);
    const [addimg, setAddimg] = useState("");
    const imgchange = (e) => {
        setAddimg(URL.createObjectURL(e.target.files[0]));
    }

    return (
        <>

            <div className="text-center">
                <h6 className="formhead">Project Team</h6>
                <Link to="#" className="anchorlink mb-3">
                    Click here for more information.
                </Link>
                <button type="button" className="btn anchorlink text-decoration-underline" onClick={() => setYessix(false)}>
                    <b>Click to add</b>
                </button>
            </div>
            {yessix == false &&
                <Form>
                    <Form.Group className="mb-4">
                        <Form.Label>Official Names</Form.Label>
                        <Form.Control type="text" placeholder="Official Names"></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>National ID Number or Passport number</Form.Label>
                        <Form.Control type="text" placeholder="ID or Passport Number"></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Project Position/Title, e.g. CEO, CTO, etc.</Form.Label>
                        <Form.Control type="text" placeholder="Project Position"></Form.Control>
                    </Form.Group>
                    <div className="flexbox justify-content-between mb-4">
                        <div>
                            <Form.Check type="checkbox" label="Advisory Member"></Form.Check>
                        </div>
                        <div>
                            <Form.Check type="checkbox" label="Core Team Member"></Form.Check>
                        </div>
                    </div>
                    <Form.Group className="mb-4">
                        <Form.Label>LinkedIn account</Form.Label>
                        <Form.Control type="text" placeholder="Linkedin Account"></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Country where ID was Issued</Form.Label>
                        <Form.Control type="text" placeholder=""></Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <div className="flexbox align-items-start justify-content-between">
                            <div>
                                <Form.Label>Member Image</Form.Label>
                                <img src={addimg} alt="Member" className="img-fluid memberimg" />
                            </div>
                            <div className="addimg">
                                <input type="file" onChange={imgchange} accept="image/*" className="form-control" />
                                <div className="addimgbox">
                                    Click to add
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
            }


        </>

    )
}

export default Projectteam;