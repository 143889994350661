import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Accordion, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "react-bootstrap";
import "../Karthick.css";
import Images from "../Components/Images";
import { MdOutlineKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import Switch from "react-switch";
import Liquidtytable from "../Components/Modals/liquidtytable";
import Roicalculator from '../Components//Modals/roicalculator';
import Walletpage from "../Components/Modals/walletmodal";
import Announcement from "../Components/announcement";

function Farms(props) {
  const [Walletmodal, setWalletmodal] = useState(false);
  const [statusTab, seStatusTab] = useState('live');
  const [accordionOpen, setAccordionOpen] = useState();
  const toggleAccordion = (i) => {
    setAccordionOpen(i === accordionOpen ? null : i);
  };
  const [accordiontable, setAccordiontable] = useState([
    { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 },
  ]);
  const [checked, setChecked] = useState(true);
  const handleChange = nextChecked => {
    setChecked(nextChecked);
  };
  const [checkeds, setCheckeds] = useState(true);
  const handleChanges = nextChecked => {
    setCheckeds(nextChecked);
  };
  const [liquimodal, setLiquimodal] = useState(false);
  const [roical, setRoical] = useState(false);
  const [types, setTypes] = useState(1)
  const [changefirst, setChangefirst] = useState({
    id: 1, image: Images.eth, coinname: "ETH"
  });
  const [changesecond, setChangesecond] = useState({
    id: 1, image: Images.usdc, coinname: "USDC"
  })
  const onswap = () => {
    var changea = changefirst
    var changeb = changesecond
    setChangefirst(changeb)
    setChangesecond(changea)
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      {Walletmodal && <Walletpage onDismiss={() => setWalletmodal(false)} />}
      {roical && <Roicalculator onDismiss={() => setRoical(false)} changefirst={changefirst} changesecond={changesecond} onswap={onswap} />}
      {liquimodal && <Liquidtytable onDismiss={() => setLiquimodal(false)} />}
      <Innerheader />

      <Container fluid className="p-0 farms__whole">
        
      <div className="container innerpagess">
        {/* <Announcement/> */}
        </div>
   
        <div className="d-flex align-items-end position-relative">
      
          <img src={Images.backlogo} className='backlogo farms__backlogo' />
          <img src={Images.elogo} className='elogo farms__elogo' />
      
          <div className="farms__bannerImg py-4 py-lg-0 position-relative d-flex justify-content-center align-items-center">
      
            <Container>
              
              <Row className="align-items-center">
                <Col lg={12} xl={10} xxl={9} className="mx-auto">
                  <div className="farms__titleGlass rounded-4 p-3 p-sm-4">
                    <Row className="align-items-center">
                      <Col md={6} className="mb-4 mb-md-0">
                        <h2 className="m-0 inter_font farms__title">Farms</h2>
                        <h6 className="m-0 poppins_font farms__subTitle mt-1">Stake LP tokens to earn</h6>
                        <button className="farms_galssBtn rounded-3 poppins_font mt-3">Community Auctions <i class="fa-solid fa-chevron-right" /></button>
                      </Col>
                      <Col md={6}>
                        <div className="farms__whiteGalss rounded-4 p-3 psm-4">
                          <p className="m-0 poppins_font farms__whiteGlassTtl">Yield Booster</p>
                          <p className="m-0 poppins_font farms__whiteGlassSkyText mt-3">Connect wallet to view booster</p>
                          <p className="m-0 poppins_font farms__whiteGlassHint mt-2">An active fixed-terms staking position is required
                            for activating farm yield boosters.</p>
                          <button className="btn btn-brand-1 sitebtn hover-up mt-3">Connect Wallet</button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

            </Container>
          </div>
        </div>

        <Container className="pb-5">
          <div className="farms__table_holder rounded-4 p-3 p-sm-4 p-xxl-5 mt-4">
            <Row>
              <Col lg={6} className="mb-4 mb-lg-0">
                <div className="farms_tableOptLeft d-flex flex-wrap gap-3 justify-content-center justify-content-xl-start align-items-center w-100">
                  {/* <button className="farms__hamburger">
                    <i class="fa-solid fa-bars"></i></button> */}

                  <div className="farms__tableToggle rounded-1">
                    <button className={`poppins_font ${statusTab == 'live' ? 'farms__toggleButtonActive' : 'farms__toggleButton'}`} onClick={() => seStatusTab('live')}>Live</button>
                    <button className={`poppins_font  ${statusTab == 'finished' ? 'farms__toggleButtonActive' : 'farms__toggleButton'}`} onClick={() => seStatusTab('finished')}>Finished</button>
                  </div>

                  {/* <button className="poppins_font farms__typesBtn">Farms Types</button> */}
                  <Dropdown className="farms_type">
                    <DropdownToggle>Farms Types</DropdownToggle>
                    <DropdownMenu className="px-3 py-3">
                      <div className="d-flex justify-content-between mb-2">
                        <h6 className="fw-600">V2 Farms</h6>
                        <Switch
                          onChange={handleChange}
                          checked={checked}
                          offColor="#162019"
                          onColor="#1888b7"
                          offHandleColor="#ffff"
                          onHandleColor="#00e7fa"
                          height={20}
                          width={36}
                          handleDiameter={18}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          className="react-switch"
                          id="material-switch"
                        />
                      </div>
                      <div className="d-flex justify-content-between mb-0">
                        <h6 className="fw-600">V1 Farms</h6>
                        <Switch
                          onChange={handleChanges}
                          checked={checkeds}
                          offColor="#162019"
                          onColor="#1888b7"
                          offHandleColor="#ffff"
                          onHandleColor="#00e7fa"
                          height={20}
                          width={36}
                          handleDiameter={18}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          className="react-switch"
                          id="material-switch"
                        />
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                  <div className="d-flex justify-content-start align-items-center gap-2">
                    <label class="switch ">
                      <input type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                    <p className="farms__switchText m-0 poppins_text">stake only</p>
                  </div>
                </div>
              </Col>

              <Col lg={6} className="d-flex justify-content-center justify-content-lg-end align-items-center gap-4">
                <Row>
                  <Col sm={6} className="mb-4">
                    <div className="d-flex justify-content-between justify-content-sm-start align-items-center gap-2">
                      <p className="farms__switchText m-0 poppins_text">Sort By</p>
                      <Dropdown className="farms__tableOptDrop">
                        <Dropdown.Toggle id="dropdown-basic">
                          Hot<i class="fa-solid fa-angle-down ms-4"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="farms__tableOptDropMenu">
                          <Dropdown.Item >APR</Dropdown.Item>
                          <Dropdown.Item >Multiplier</Dropdown.Item>
                          <Dropdown.Item >Earned</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="d-flex justify-content-between justify-content-sm-end align-items-center gap-2">
                      <p className="farms__switchText m-0 poppins_text">Search</p>
                      <input type="text" className="farms__tableOptSearch" placeholder="Search Farms" />
                    </div>
                  </Col>
                </Row>



              </Col>
            </Row>


            <div className="farms__accordHolder pb-5 rounded-4 p-2 my-5">

              <Accordion defaultActiveKey={1} className="farms__accordion rounded-4" flush>
                {accordiontable.map((e, i) =>
                  <Accordion.Item eventKey={i + 1}>
                    <Accordion.Header onClick={() => toggleAccordion(i + 1)}>
                      <div className="farms__accordHeader d-flex  justify-content-between align-items-start w-100 gap-4">

                        <div className="farms__tableLogoCont d-flex justify-content-start align-items-center gap-2 farms__cmnChild">
                          <div className="farms__tableImgHolder position-relative">
                            <img src={Images.busd} className="img-fluid farms__busdLogo" />
                            <img src={Images.edaface} className="img-fluid farms__edafaceLogo position-relative" />
                          </div>
                          <p className="m-0 poppins_font farms__coinName">EDA-BUSD LP</p>
                        </div>

                        <div className="poppins_font farms__typesBtn farms__accordHeadBtn rounded-pill  farms__cmnChild">0.25%</div>
                        <div className="poppins_font farms__typesBtn farms__accordHeadBtn rounded-pill  farms__cmnChild"><img src={Images.rocket} className="img-fluid farms__accordRocket me-1" />Boosted</div>
                        <div className="farms__accordHeadEarn farms__cmnChild">
                          <p className="farms__earnText poppins_font m-0">Earned</p>
                          <p className="farms__liquidityPrice poppins_font m-0">0</p>
                        </div>

                        <div className="farms__accordHeadPercentHolder farms__cmnChild">
                          <p className="farms__earnText poppins_font m-0">APR</p>
                          <div className="farms__accordHeadPercent d-flex justify-content-start align-items-center gap-1 mt-1">
                            <img src={Images.rocket} className="img-fluid farms__accordRocket me-1" />
                            <p className="farms__percentText poppins_font m-0">Up to 122.67% <span className="ms-2">69.87%</span></p>
                            <img src={Images.calculator} className="img-fluid farms__accordRocket me-1" onClick={() => setRoical(true)} />
                          </div>
                        </div>

                        <div className="farms__accordHeadLiquidity farms__cmnChild">
                          <p className="farms__earnText poppins_font m-0">Staked Liquidity</p>
                          <p className="farms__liquidityPrice poppins_font m-0">$9,848,894</p>
                        </div>
                        <div className="farms__cmnChild">
                          <button className="farms__hamburger">
                            <i class="fa-regular fa-circle-question" /></button>
                        </div>
                        <div className="farms__accordHeadLiquidity farms__cmnChild">
                          <p className="farms__earnText poppins_font m-0">Multiplier</p>
                          <p className="farms__liquidityPrice poppins_font m-0">3.3x<button className="farms__hamburger ms-2">
                            <i class="fa-regular fa-circle-question" /></button></p>
                        </div>

                        <div className="farms__accordHeadLiquidity farms__cmnChild">
                          <p className="farms__earnText poppins_font m-0">Available</p>
                          <p className="farms__liquidityPrice poppins_font m-0">0 LP</p>
                        </div>

                        <div className="farms__accordHeadLiquidity farms__cmnChild">
                          <p className="farms__earnText poppins_font m-0">Staked</p>
                          <p className="farms__liquidityPrice poppins_font m-0">0 LP</p>
                        </div>
                        <div className="farms__accordHeadLiquidity farms__cmnChild">
                          {accordionOpen == i + 1 ? <MdOutlineKeyboardArrowDown className="arrwoup" /> : <MdKeyboardArrowUp className="arrwoup" />}
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="farms__table_holder rounded-1 p-3">
                        <Row className="align-items-center">
                          <Col lg={2} md={3} className="d-flex flex-column flex-sm-row justify-content-between align-items-start gap-3 mb-4 mb-md-0 flex-md-column">
                            <p className="m-0 poppins_font farms__coinName">Add EDA-BNB LP</p>
                            <p className="m-0 poppins_font farms__coinName">See Pair Info</p>
                            <p className="m-0 poppins_font farms__coinName">View Contract</p>
                          </Col>

                          <Col lg={10} md={9}>
                            <div className="farms__toggleButtonActive farms__accordBodyBox rounded-1 p-4">
                              <p className="m-0 poppins_font farms__coinName text-center">No Position Found</p>
                              {localStorage.getItem("metamask") !== "true" ?
                                <button className="btn btn-brand-1 sitebtn hover-up mt-3 w-100" onClick={() => { setWalletmodal(true) }}>Connect Wallet</button> :
                                <button className="btn btn-brand-1 sitebtn hover-up mt-3 w-100" onClick={() => setLiquimodal(true)}>Add Liquidity</button>}
                            </div>
                          </Col>
                        </Row>

                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </Accordion>
            </div>
          </div>
        </Container>
      </Container>

      <Footer />
    </div>
  );
}

export default Farms;
