import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Table, InputGroup } from "react-bootstrap";
import Images from "../../Components/Images";
import { GoPlus } from "react-icons/go";
import { GoMail } from "react-icons/go";
import { MdOutlineLock } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";

const Submitrequest = (props) => {

    const location = useLocation();
    console.log(location,"asasda");
    return (
        <>

            <div className="text-center">
                <h6 class="formhead">Submit a request</h6>
                <p className="content">
                    Please provide the following details
                </p>
            </div>
            <Form>
                <InputGroup className="mb-4">
                    <InputGroup.Text><GoMail /></InputGroup.Text>
                    <Form.Control type="text" placeholder="Company email address"></Form.Control>
                </InputGroup>
                <InputGroup className="mb-4">
                    <InputGroup.Text><GoMail /></InputGroup.Text>
                    <Form.Control type="text" placeholder="Domain name"></Form.Control>
                </InputGroup>
                <InputGroup className="mb-4">
                    <InputGroup.Text><MdOutlineLock /></InputGroup.Text>
                    <Form.Control type="text" placeholder="Password"></Form.Control>
                </InputGroup>
                <div className="flexbox justify-content-between mb-4">
                    <div>
                        <div className="d-flex align-items-center">
                            <Form.Check type="checkbox"></Form.Check>
                            <Form.Label>
                                <span className="content noteblue d-block">
                                    <b>Submit New Project</b>
                                </span>
                                {props.path == '/cointoken' ?
                                "(Coin or Token)":"(Exchange, Marketplace, AI, etc)"}
                            </Form.Label>
                        </div>
                    </div>
                    <div>
                        <div className="d-flex align-items-center">
                            <Form.Check type="checkbox"></Form.Check>
                            <Form.Label>
                                <span className="content noteblue d-block">
                                    <b>Upgrade Listed Project</b>
                                </span>
                                {props.path == '/cointoken' ?
                                "(Coin or Token)":"(Exchange, Marketplace, AI, etc)"}
                            </Form.Label>
                        </div>
                    </div>
                </div>
            </Form>


        </>

    )
}

export default Submitrequest;