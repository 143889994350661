import React, { useEffect, useState } from "react";
import { Button, Container, Row } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import edafacelogo from "../Assets/edaface-logo1.png";
import edafacelogos from "../Assets/dex_logo.png";
import Headermobile from "./headermobile";
import Walletpage from "../Components/Modals/walletmodal";
import { FaWallet } from "react-icons/fa";

import Connectbutton from "./connectbutton";
import Images from "../Components/Images";
import Announcement from "../Components/announcement";
import GTranslateWidget from "./gtranslate";

export default function Header() {
  const [headermobile, setHeadermobile] = useState(false);
  const [Walletmodal, setWalletmodal] = useState(false);

  return (
    <>
      {headermobile && (
        <Headermobile onDismiss={() => setHeadermobile(false)} />
      )}
      {Walletmodal && <Walletpage onDismiss={() => setWalletmodal(false)} />}
      <div>
        <header className="header pt-0 pb-2 sticky-bar mainheadss">
          <div className="first_head">
            <div className="container d-flex justify-content-end pt-2">
              {/* <Link className="me-2" to="/login">Login</Link> */}
              <p className="mb-0 text-white adtop">Bringing New Insight For Your Financial Freedom!</p>
            </div>
          </div>
          <div className="container max_contain">
            {/* <div className="mx-0 text-end"><GTranslateWidget/></div> */}
            <div className="main-header">
              <div className="header-left">
                <div className="header-logo">
                  <Link className="d-flex" to="https://edaface.com/">
                    <img src={edafacelogo} className="logos f1logo" alt="logos" />
                  </Link>
                </div>
                <div className="header-logo ms-3 ms-xl-2 ms-xxl-3">
                  <Link
                    // className="d-flex" 
                    to="/">
                    <img
                      src={edafacelogos}
                      className="logos me-3 me-xl-2 me-xxl-3"
                      alt="logos"
                    />
                  </Link>
                </div>


                <div className="header-nav">
                  <nav className="nav-main-menu d-none d-xl-block">

                    <div className="ruby-menu-demo-header">
                      <div className="ruby-wrapper fheader setdesk">
                        {/* <GTranslateWidget/> */}
                        <ul className="ruby-menu">
                          {/* <li>
                            <Link to="/">
                              <svg
                                className="laguimg"
                                xmlns="http://www.w3.org/2000/svg"
                                width="23"
                                height="21"
                                viewBox="0 0 23 21"
                                fill="none"
                              >
                                <path
                                  d="M12.3646 13.6146L9.71875 11L9.75 10.9688C11.5147 9.0074 12.8332 6.68672 13.6146 4.16667H16.6667V2.08333H9.375V0H7.29167V2.08333H0V4.16667H11.6354C10.9375 6.16667 9.83333 8.07292 8.33333 9.73958C7.36458 8.66667 6.5625 7.48958 5.92708 6.25H3.84375C4.60417 7.94792 5.64583 9.55208 6.94792 11L1.64583 16.2292L3.125 17.7083L8.33333 12.5L11.5729 15.7396L12.3646 13.6146ZM18.2292 8.33333H16.1458L11.4583 20.8333H13.5417L14.7083 17.7083H19.6562L20.8333 20.8333H22.9167L18.2292 8.33333ZM15.5 15.625L17.1875 11.1146L18.875 15.625H15.5Z"
                                  fill="#2D699B"
                                />
                              </svg>{" "}
                              Language
                            </Link>
                            <ul className="">
                              <li>
                                <Link to="/">
                                  <img
                                    src={Images.eng}
                                    className="img-fluid navimg me-1"
                                    alt="images"
                                  />{" "}
                                  English
                                </Link>
                              </li>
                              <li>
                                <Link to="/">
                                  <img
                                    src={Images.jap}
                                    className="img-fluid navimg me-1"
                                    alt="images"
                                  />{" "}
                                  Others
                                </Link>
                              </li>
                            </ul>
                            <span className="ruby-dropdown-toggle"></span>
                          </li> */}

                          <li>
                            <Link to="/">List Your Project</Link>
                            <ul className="">
                              <li>
                                <Link to="https://listing.edaface.com/project" target="_blank">
                                  <img
                                    src={Images.presale}
                                    className="img-fluid navimg me-1"
                                    alt="images"
                                  />{" "}
                                  List Your Presale
                                </Link>
                              </li>
                              <li>
                                <Link to="/cointoken"
                                // target="_blank"
                                >
                                  <img
                                    src={Images.cointoken}
                                    className="img-fluid navimg me-1"
                                    alt="images"
                                  />{" "}
                                  List Your Coin and Token
                                </Link>
                              </li>
                              <li>
                                <Link to="/exchange"
                                // target="_blank"
                                >
                                  <img
                                    src={Images.exchange}
                                    className="img-fluid navimg me-1"
                                    alt="images"
                                  />{" "}
                                  List Your Exchange, Marketplace, etc
                                </Link>
                              </li>
                            </ul>
                            <span className="ruby-dropdown-toggle"></span>
                          </li>

                          {/* <li className="ruby-menu-mega">
                            <Link to="/">$ EDA</Link>
                            <ul className="">
                              <li>
                                <Link to="/">
                                  <img
                                    src={Images.buyicon}
                                    className="img-fluid navimg me-1"
                                    alt="images"
                                  />{" "}
                                  Buy EDA Coin
                                </Link>
                              </li>
                            </ul>
                            <span className="ruby-dropdown-toggle"></span>
                          </li> */}

                          {/* <li>
                            <Link to="/">Chains</Link>
                            <ul>
                              <li>
                                <Link to="/">
                                  <img
                                    src={Images.presale}
                                    className="img-fluid navimg me-1"
                                    alt="images"
                                  />{" "}
                                  BNB Chain
                                </Link>
                              </li>
                              <li>
                                <Link to="/">
                                  <img
                                    src={Images.cointoken}
                                    className="img-fluid navimg me-1"
                                    alt="images"
                                  />{" "}
                                  Arbitrum or ZK sync
                                </Link>
                              </li>
                              <li>
                                <Link to="/">
                                  <img
                                    src={Images.exchange}
                                    className="img-fluid navimg me-1"
                                    alt="images"
                                  />{" "}
                                  Polygon Matics
                                </Link>
                              </li>
                              <li>
                                <Link to="/">
                                  <img
                                    src={Images.avalan}
                                    className="img-fluid navimg me-1"
                                    alt="images"
                                  />
                                  Avalanche
                                </Link>
                              </li>
                            </ul>
                          </li> */}

                          <li className="ruby-menu-mega">
                            <Link to="/">Build on EdaFace</Link>
                            <ul className="">
                              <li>
                                <Link to="/" target='_blank'>
                                  <img
                                    src={Images.docss}
                                    className="img-fluid navimg me-1"
                                    alt="images"
                                  />{" "}
                                  Docs
                                </Link>
                              </li>
                              <li>
                                <Link to="/" target='_blank'>
                                  <img
                                    src={Images.square}
                                    className="img-fluid navimg me-1"
                                    alt="images"
                                  />{" "}
                                  Resources
                                </Link>
                              </li>
                            </ul>
                            <span className="ruby-dropdown-toggle"></span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>

                <div className="header-right">
                  <div className="d-none d-md-block">
                    <Connectbutton />
                  </div>
                  <div
                    className="burger-icon burger-icon-white"
                    onClick={() => {
                      setHeadermobile(true);
                    }}
                  >
                    <span className="burger-icon-top"></span>
                    <span className="burger-icon-mid"></span>
                    <span className="burger-icon-bottom"></span>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <Announcement />
        </header>

      </div>
    </>
  );
}
