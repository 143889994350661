import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Table, InputGroup } from "react-bootstrap";
import Images from "../../Components/Images";


const Whitepaper = () => {

    return (
        <>         
                    <div className="text-center">
                        <h6 class="formhead">Whitepaper</h6>
                    </div>
                    <Form>
                        <Form.Group className="mb-4">
                            <Form.Label>Whitepaper url</Form.Label>
                            <Form.Control type="text" className="borderin" placeholder="Please include the link to your project whitepaper"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-4">
                            <Form.Label>Any Litepaper?</Form.Label>
                            <Form.Control type="text" className="borderin" placeholder="If yes, include the Litepaper url"></Form.Control>
                        </Form.Group>                     
                    </Form>         
        </>

    )
}

export default Whitepaper;