import React, { useEffect, useState } from "react";
import { Tab, Tabs, Accordion, Button, Offcanvas } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";
import edafacelogo from "../Assets/edaface-logo1.png";
import { IoClose } from "react-icons/io5";
import docss from "../Assets/navicon/docss.png";
import square from "../Assets/navicon/square.png";
import Connectbutton from './connectbutton';



export default function Innerheadermobile(props) {

    const [innerheadermobile, setInnerheadermobile] = useState(true);

    return (

        <>
            <div>

                <div className='viewcanvas'>
                    <Offcanvas show={innerheadermobile} placement='end' name="end">
                        <Offcanvas.Header>
                            <Link className="d-flex" to="https://edaface.com/"><img src={edafacelogo} className="logos" alt="logos" /></Link>
                            <button className='btn close' onClick={() => props.onDismiss()}><IoClose /></button>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <div className="d-block d-md-none"><Connectbutton/></div>
                            <div>
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Trade</Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                {/* <li><Link to="/swap" onClick={() => props.onDismiss()}><img src={docss} className="img-fluid navimg me-1" alt="images" /> Swap</Link></li> */}
                                                <li><Link to="/spot" onClick={() => props.onDismiss()}><img src={docss} className="img-fluid navimg me-1" alt="images" /> Spot</Link></li>
                                                <li><Link to="/future" onClick={() => props.onDismiss()}><img src={square} className="img-fluid navimg me-1" alt="images" /> Futures Perpetual</Link></li>
                                                <li><Link to="/option" onClick={() => props.onDismiss()}><img src={square} className="img-fluid navimg me-1" alt="images" /> Options</Link></li>                                            
                                               <li><Link to="/synthetics" onClick={() => props.onDismiss()}><img src={square} className="img-fluid navimg me-1" alt="images" /> Synthetics</Link></li>
                                                <li><Link to="https://p2pmarket.edaface.com/" target="_blank" onClick={() => props.onDismiss()}><img src={square} className="img-fluid navimg me-1" alt="images" /> P2P Market</Link></li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>NFTs</Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li><Link to="/"  target='_blank' onClick={() => props.onDismiss()}><img src={docss} className="img-fluid navimg me-1" alt="images" /> NFT Marketplace</Link></li>
                                                <li><Link to="/"  target='_blank' onClick={() => props.onDismiss()}><img src={square} className="img-fluid navimg me-1" alt="images" /> NFT Staking</Link></li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header className="earnsub">
                                            <Link to="/earn" onClick={() => props.onDismiss()}>  Earn </Link>
                                        </Accordion.Header>

                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Ecosystem</Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li><Link to="/"  target='_blank' onClick={() => props.onDismiss()}><img src={docss} className="img-fluid navimg me-1" alt="images" /> Bridges</Link></li>
                                                <li><Link to="/"  target='_blank' onClick={() => props.onDismiss()}><img src={square} className="img-fluid navimg me-1" alt="images" /> Chat Forum</Link></li>
                                                <li><Link to="/"  target='_blank' onClick={() => props.onDismiss()}><img src={square} className="img-fluid navimg me-1" alt="images" /> Developers</Link></li>
                                                <li><Link to="/"  target='_blank' onClick={() => props.onDismiss()}><img src={square} className="img-fluid navimg me-1" alt="images" /> Launchpad</Link></li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>Staking</Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li><Link to="/pools" onClick={() => props.onDismiss()}><img src={docss} className="img-fluid navimg me-1" alt="images" /> MIM Pools</Link></li>
                                                <li><Link to="/farms" onClick={() => props.onDismiss()}><img src={square} className="img-fluid navimg me-1" alt="images" /> Farms</Link></li>
                                                <li><Link to="/pond" onClick={() => props.onDismiss()}><img src={square} className="img-fluid navimg me-1" alt="images" /> Ponds</Link></li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>More</Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li><Link to="/"  target='_blank' onClick={() => props.onDismiss()}><img src={docss} className="img-fluid navimg me-1" alt="images" /> Docs</Link></li>
                                                <li><Link to="/"  target='_blank' onClick={() => props.onDismiss()}><img src={square} className="img-fluid navimg me-1" alt="images" /> Resources</Link></li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Offcanvas.Body>
                    </Offcanvas>






                </div>

            </div>

        </>

    );
}