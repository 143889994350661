import React, { useState, useEffect, useRef } from 'react';
import { Tab, Tabs, Accordion, Button, Offcanvas } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";
import edafacelogo from "../Assets/edaface-logo1.png";
import { IoClose } from "react-icons/io5";
import eng from "../Assets/navicon/eng.png";
import jap from "../Assets/navicon/jap.png";
import presale from "../Assets/navicon/presale.png";
import cointoken from "../Assets/navicon/cointoken.png";
import exchange from "../Assets/navicon/exchange.png";
import buyicon from "../Assets/navicon/buy-icon.png";
import avalan from "../Assets/navicon/avalan.png";
import docss from "../Assets/navicon/docss.png";
import square from "../Assets/navicon/square.png";
import Connectbutton from './connectbutton';



const Codedetail = (props) => {

    const [headermobile, setHeadermobile] = useState(true);

    return (

        <>
            <div className='viewcanvas'>
                <Offcanvas show={headermobile} placement='end' name="end">
                    <Offcanvas.Header>
                        <Link className="d-flex" to="https://edaface.com/"><img src={edafacelogo} className="logos" alt="logos" /></Link>
                        <button className='btn close' onClick={() => props.onDismiss()}><IoClose /></button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="d-block d-md-none"><Connectbutton/></div>
                        <div>
                            <Accordion>
                                {/* <Accordion.Item eventKey="0">
                                    <Accordion.Header><svg className="laguimg me-2" xmlns="http://www.w3.org/2000/svg" width="23" height="21" viewBox="0 0 23 21" fill="none">
                                        <path d="M12.3646 13.6146L9.71875 11L9.75 10.9688C11.5147 9.0074 12.8332 6.68672 13.6146 4.16667H16.6667V2.08333H9.375V0H7.29167V2.08333H0V4.16667H11.6354C10.9375 6.16667 9.83333 8.07292 8.33333 9.73958C7.36458 8.66667 6.5625 7.48958 5.92708 6.25H3.84375C4.60417 7.94792 5.64583 9.55208 6.94792 11L1.64583 16.2292L3.125 17.7083L8.33333 12.5L11.5729 15.7396L12.3646 13.6146ZM18.2292 8.33333H16.1458L11.4583 20.8333H13.5417L14.7083 17.7083H19.6562L20.8333 20.8333H22.9167L18.2292 8.33333ZM15.5 15.625L17.1875 11.1146L18.875 15.625H15.5Z" fill="#2D699B" />
                                    </svg>  Language</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li><Link to="/" onClick={() => props.onDismiss()}><img src={eng} className="img-fluid navimg me-1" alt="images" /> English</Link></li>
                                            <li><Link to="/" onClick={() => props.onDismiss()}><img src={jap} className="img-fluid navimg me-1" alt="images" /> Others</Link></li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item> */}
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>List Your Project</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li><Link to="https://listing.edaface.com/project" onClick={() => props.onDismiss()}><img src={presale} className="img-fluid navimg me-1" alt="images" /> List Your Presale</Link></li>
                                            <li><Link to="https://listing.edaface.com/project" onClick={() => props.onDismiss()}><img src={cointoken} className="img-fluid navimg me-1" alt="images" /> List Your Coin and Token</Link></li>
                                            <li><Link to="https://listing.edaface.com/project" onClick={() => props.onDismiss()}><img src={exchange} className="img-fluid navimg me-1" alt="images" /> List Your Exchange,
                                                Marketplace, etc</Link></li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                
                                {/* <Accordion.Item eventKey="2">
                                    <Accordion.Header>$EDA</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li><Link to="/"><img src={buyicon} className="img-fluid navimg me-1" alt="images" /> Buy EDA Token</Link></li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Chains</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li><Link to="/" onClick={() => props.onDismiss()}><img src={presale} className="img-fluid navimg me-1" alt="images" /> BNB Chain</Link></li>
                                            <li><Link to="/" onClick={() => props.onDismiss()}><img src={cointoken} className="img-fluid navimg me-1" alt="images" /> Arbitrum or ZK sync</Link></li>
                                            <li><Link to="/" onClick={() => props.onDismiss()}><img src={exchange} className="img-fluid navimg me-1" alt="images" /> Polygon Matics</Link></li>
                                            <li><Link to="/" onClick={() => props.onDismiss()}><img src={avalan} className="img-fluid navimg me-1" alt="images" />Avalanche</Link></li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item> */}
                             
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Build on EdaFace</Accordion.Header>
                                    <Accordion.Body>
                                        <ul>
                                            <li><Link to="/"  target='_blank' onClick={() => props.onDismiss()}><img src={docss} className="img-fluid navimg me-1" alt="images" /> Docs</Link></li>
                                            <li><Link to="/"  target='_blank' onClick={() => props.onDismiss()}><img src={square} className="img-fluid navimg me-1" alt="images" /> Resources</Link></li>
                                        </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>


        </>
    )
}

export default Codedetail;
