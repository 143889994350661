import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Table, InputGroup } from "react-bootstrap";
import Images from "../../Components/Images";
import { Link } from "react-router-dom";

const Congrats = () => {

    return (
        <>
            <div className="tablebox">
                <img src={Images.congratsimg} alt="" className="img-fluid congratsimg" />
                <div className="notebox">
                    <p className="content noteblue mt-1 mb-3">
                        Your Project is a Cautious Investment
                    </p>
                    <p className="content t-orange mb-3">
                        However, there is room for improvement in order to become more attractive to users.
                    </p>
                    <p className="content text-black mb-3">
                        If you want to see the full analysis of your score that will show area of improvement, click  here….(see attached score analysis)
                    </p>
                    <p className="content text-black">
                        You can proceed to submit your Project for listing on EdaFace
                    </p>
                </div>
            </div>

        </>


    )
}

export default Congrats;