import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Accordion, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import { MdDashboard } from "react-icons/md";
import Optiontable from "../Components/tables/optiontable";
import Heatmap from "../Components/Chartss/heatmap";
import { MdKeyboardArrowRight } from "react-icons/md";
import Optionmodal from "../Components/optionmodal";
import Tradingview from "../Components/Chartss/tradingview";
import Progressprice from "../Components/progressprice";
import CoinPairPrice from "./CoinPairPrice";
import Orderbooktable from "../Components/orderbooktable";
import { MdKeyboardArrowDown } from "react-icons/md";
import Recentcallstable from "../Components/recentcallstable";
import Recentputstable from "../Components/recentputstable";
import Callstable from "../Components/tables/calltable";
import Putstable from "../Components/tables/putstable";
import Strikestable from "../Components/tables/striketable";
import Symbolmodal from "../Components/symbolmodal";
import Position from "../Components/Historytable/position";
import Openorders from "../Components/Historytable/openorders";
import Orderhistory from "../Components/Historytable/orderhistory";
import Announcement from "../Components/announcement";

function Optionpage() {
    const [modaloption, setModaloption] = useState(true);
    const [netlist, setNetlist] = useState([
        { id: 0, img: Images.btc, netname: "BTCUSDT", calc: "85,589.6", vol: "BVOL: 85.91" },
        { id: 1, img: Images.eth, netname: "ETHUSDT", calc: "85,589.6", vol: "BVOL: 85.91" },
        { id: 2, img: Images.bnb, netname: "BNBUSDT", calc: "85,589.6", vol: "BVOL: 85.91" },
        { id: 3, img: Images.snx, netname: "XRPUSDT", calc: "85,589.6", vol: "BVOL: 85.91" },
        { id: 4, img: Images.dai, netname: "DOGEUSDT", calc: "85,589.6", vol: "BVOL: 85.91" },
    ]);
    const [selectn, setSelectn] = useState({ id: 2 });
    const [numbers, setNumbers] = useState([
        { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }
    ]);
    const [selectrange, setSelectrange] = useState({ id: "15,000" });
    const [ranges, setRanges] = useState([
        { id: "15,000" }, { id: "18,000" }, { id: "20,000" }, { id: "22,000" }, { id: "24,000" }
    ]);
    const [isChecked, setIsChecked] = useState(false);
    const handleOnChange = (event) => {
        setIsChecked(event.target.checked);
    };
    const [isCheckedss, setIsCheckedss] = useState(false);
    const handleOnChangess = (event) => {
        setIsCheckedss(event.target.checked);
    };
    const [candleTab, setCandleTab] = useState("square");
    const [activesTab, setActivesTab] = useState(0);
    const handleTabClick = (tabIndex) => {
        setActivesTab(tabIndex);
    };
    const [mapsacivetab, setMapsactivetab] = useState("all");
    const handleTabsClick = (tab) => {
        setMapsactivetab(tab);
    };
    const [optiontables, setOptiontables] = useState([
        { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 },
    ]);
    const [positiontabs, setPositiontabs] = useState("position")
    const [detailstabss, setDetailstabss] = useState();
    const [checkright, setCheckright] = useState(false);
    const handleOnChanges = (event) => {
        setCheckright(event.target.checked);
    };
    const[mobilecall,setMobilecall] = useState("calls")
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    useEffect(() => {
    
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    return (
        <>
            {modaloption && <Optionmodal onDismiss={() => setModaloption(false)} detailstabss={detailstabss} onChange={(e) => handleOnChanges(e)} checkright={checkright} />}
            <div>
                <Innerheader />

                <Container fluid className="innerpage pools_sec assetpage px-0 pos">
                   
                    <div className="off_arrow cursor" onClick={() => setModaloption(true)}><MdKeyboardArrowRight /></div>

                    <Container className="pt-1 container_max">
                    <h3 className="themeclr fw-600 text-center mt-3 mb-3">Option Trading</h3>
                    {/* <Announcement/>  */}
                        <img src={Images.backlogo} className='backlogo farms__backlogo'/>

                        <div className="option_border py-3">
                            <div className="d-block d-sm-flex px-3 mt-3 mt-sm-0">
                                <ul className="stone_ul mb-0 d-flex just mx-auto me-sm-2">
                                    <li className={candleTab === "square" ? "active" : ""} onClick={() => setCandleTab("square")}><img src={Images.stone} className="img-fluid" /></li>
                                    <li className={candleTab === "candles" ? "active" : ""} onClick={() => setCandleTab("candles")}><img src={Images.candle} className="img-fluid" /></li>
                                </ul>
                                <div className="option_net w-100 d-flex align-items-center justify-content-between mt-3 mt-sm-0">
                                    <div className="d-flex gap w_d table-responsive">
                                        {netlist.map((e, i) =>
                                            <div onClick={() => handleTabClick(i)} className={activesTab == i ? 'd-flex align-items-center grayhover active py-2 px-2' : 'd-flex align-items-center grayhover py-2 px-2'}>
                                                <img src={e.img} className="img-fluid me-2 btcimg" />
                                                <div>
                                                    <h6 className="blkcr fw-600 mb-1">{e.netname}</h6>
                                                    <span><span className="bname me-1">{e.calc}</span> <span className="bsubname">{e.vol}</span></span>
                                                </div>
                                            </div>)}
                                    </div>
                                    <Dropdown>
                                        <DropdownToggle>{windowWidth < 576 ? <MdDashboard /> : <span><MdDashboard /> Tools</span>} </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem>Easy Options</DropdownItem>
                                            <DropdownItem>Optional Data</DropdownItem>
                                            <DropdownItem>Calculator</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </div>

                            {candleTab === "square" &&
                                <>
                                    <div className="mt-3 d-block d-md-flex align-items-center justify-content-between px-3">
                                        <ul className="d-flex mb-2 mb-sm-3 mb-md-0 gap-3 option_tab justify-content-center justify-content-md-start mt-3 mt-md-0">
                                            <li className={mapsacivetab === "favourite" ? "active" : ""} onClick={() => handleTabsClick('favourite')}>Favorites</li>
                                            {/* <li className={mapsacivetab === "heatmaps" ? "active" : ""} onClick={() => handleTabsClick('heatmaps')}>Heatmap</li> */}
                                            <li className={mapsacivetab === "all" ? "active" : ""} onClick={() => handleTabsClick('all')}>All</li>
                                        </ul>
                                        <div className="d-block d-sm-flex justify-content-center mt-2 mt-md-0 ms-2 ms-md-0">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={handleOnChange} checked={isChecked} />
                                                <label class="form-check-label fw-500" for="flexCheckDefault">
                                                    Around ATM
                                                </label>
                                            </div>
                                            {isChecked ? <Dropdown className="mt-2 mt-sm-0">
                                                <DropdownToggle className="py-0 px-2 ms-2">{selectn.id}</DropdownToggle>
                                                <DropdownMenu>
                                                    {numbers.map((e, i) =>
                                                        <DropdownItem onClick={() => setSelectn(e)}>{e.id}</DropdownItem>)}
                                                </DropdownMenu>
                                            </Dropdown> : <></>}
                                            <div class="form-check mx-0 mx-sm-3 mt-2 mt-sm-0">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={handleOnChangess} checked={isCheckedss} />
                                                <label class="form-check-label fw-500" for="flexCheckDefault">
                                                    Strike Range
                                                </label>
                                            </div>
                                            {isCheckedss ?
                                                <div className="d-flex me-2 mt-2 mt-md-0">
                                                    <Dropdown className="me-1">
                                                        <DropdownToggle className="py-0 px-2">{selectrange.id}</DropdownToggle>
                                                        <DropdownMenu>
                                                            {ranges.map((e, i) =>
                                                                <DropdownItem onClick={() => setSelectrange(e)}>{e.id}</DropdownItem>)}
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                    -
                                                    <Dropdown className="ms-1">
                                                        <DropdownToggle className="py-0 px-2">{selectrange.id}</DropdownToggle>
                                                        <DropdownMenu>
                                                            {ranges.map((e, i) =>
                                                                <DropdownItem onClick={() => setSelectrange(e)}>{e.id}</DropdownItem>)}
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </div>
                                                : <></>}

                                            <Dropdown className="trans mt-2 mt-sm-0 text-end text-sm-start">
                                                <DropdownToggle className="p-0" ><img src={Images.settings} className="img-fluid" /></DropdownToggle>
                                                <DropdownMenu>
                                                    <div className="d-flex">
                                                        <p className="blkclr fw-600 mb-0">Preference</p>
                                                        <p className="themeclr fw-600 mb-0 ms-4" style={{ cursor: "pointer" }}>Reset</p>
                                                    </div>
                                                    <div>
                                                        <p className="blkclr mt-2 mb-2">Displayed</p>
                                                        <div class="form-check mb-2">
                                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label class="form-check-label themeclr fw-500" for="flexCheckDefault">
                                                                Position
                                                            </label>
                                                        </div>
                                                        <div class="form-check mb-2">
                                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label class="form-check-label themeclr fw-500" for="flexCheckDefault">
                                                                Bid Price
                                                            </label>
                                                        </div>
                                                        <div class="form-check mb-2">
                                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                            <label class="form-check-label themeclr fw-500" for="flexCheckDefault">
                                                                Mark Price
                                                            </label>
                                                        </div>
                                                    </div>
                                                </DropdownMenu>
                                            </Dropdown>
                                            {/* <img src={Images.settings} className="img-fluid" /> */}
                                        </div>
                                    </div>
                                    {mapsacivetab === "favourite" &&
                                        <>
                                            <h6 className="themeclr pt-5 pb-5 text-center">You have no favorites</h6>
                                        </>}
                                    {mapsacivetab === "heatmaps" &&
                                        <Heatmap />}
                                    {mapsacivetab === "all" &&
                                        // <Optiontable data={activesTab} mapdata={optiontables} />
                                        <div>
                                            <div className="d-none d-lg-block">
                                            <div className="call_put d-flex align-items-center py-3 px-3 mt-3 text-center">
                                                <h5 className="mb-0 fw-600 flex100">Calls</h5>
                                                <div className="d-flex align-items-center">
                                                    <p className="mb-0">XRPUSDT Price : <span className="themeclr">0.0802</span> </p>
                                                    <p className="fw-600 mb-0 mx-3">2024-05-31</p>
                                                    <p className="mb-0">Time to Expiry : <span className="themeclr">1day 2hr</span> </p>
                                                </div>
                                                <h5 className="mb-0 fw-600 flex100">Puts</h5>

                                            </div></div>

                                            <div className="d-none d-lg-block">
                                            <div className="d-flex px-2">
                                                <Callstable data={activesTab} mapdata={optiontables} setCandleTab={() => setCandleTab("candles")} detailtab={(e) => setDetailstabss(e)} checkright={checkright} />
                                                <Strikestable data={activesTab} mapdata={optiontables} />
                                                <Putstable data={activesTab} mapdata={optiontables} setCandleTab={() => setCandleTab("candles")} detailtab={(e) => setDetailstabss(e)} checkright={checkright}/>
                                            </div></div>

                                            <div className="d-block d-lg-none mt-3 mb-2">
                                                <ul className="listtab modes d-flex mx-2">
                                                    <li className={mobilecall==="calls"?"active w-100 text-center":"w-100 text-center"} onClick={()=>setMobilecall("calls")}>Calls</li>
                                                    <li className={mobilecall==="puts"?"active w-100 text-center":"w-100 text-center"} onClick={()=>setMobilecall("puts")}>Puts</li>
                                                </ul>
                                                <div className="d-flex justify-content-between align-items-center mx-2">
                                                    <p className="mb-0 fw-500 f-15">XRPUSDT Price : <span className="themeclr">0.0802</span> </p>
                                                    <p className="fw-600 mb-0 f-14 mx-1">2024-05-31</p>
                                                    <p className="mb-0 fw-500 f-15">Time to Expiry : <span className="themeclr">1day 2hr</span> </p>
                                                </div>
                                                {mobilecall==="calls"&&
                                                <>        
                                                <div className="d-flex px-2">
                                                <Strikestable data={activesTab} mapdata={optiontables} />
                                                <Callstable data={activesTab} mapdata={optiontables} setCandleTab={() => setCandleTab("candles")} detailtab={(e) => setDetailstabss(e)} checkright={checkright} />
                                                </div>
                                                </>
                                                }
                                                {mobilecall==="puts"&&
                                                <>
                                                <div className="d-flex px-2">
                                                <Strikestable data={activesTab} mapdata={optiontables} />
                                                <Putstable data={activesTab} mapdata={optiontables} setCandleTab={() => setCandleTab("candles")} detailtab={(e) => setDetailstabss(e)} checkright={checkright}/>                                                </div>
                                                </>
                                                }
                                            </div>



                                        </div>

                                    }
                                </>
                            }


                            {candleTab === "candles" &&
                                <>
                                    <div class="netborder px-2 px-lg-0 mx-3 mt-4 py-2">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <Dropdown className="market ms-sm-2">
                                                    <DropdownToggle className="ps-0 d-flex align-items-center"><h5 className="themeclr mb-0">USDT 2276.46 <MdKeyboardArrowDown /></h5></DropdownToggle>
                                                    {/* <DropdownMenu>

                                                    </DropdownMenu> */}
                                                </Dropdown>
                                            </div>
                                            <div className="marginlisttop">
                                                <div className="d-flex marginlist align-items-center py-2 px-2">
                                                    <h5 className="redclr mb-0">615.0</h5>
                                                    <div><p className="mb-1 blkclr f-14 fw-500">Index</p><p className="mb-0 themeclr f-14 fw-500">27,582.07</p></div>
                                                    <div><p className="mb-1 blkclr f-14 fw-500">Mark</p><p className="mb-0 themeclr f-14 fw-500">527.4</p></div>
                                                    <div><p className="mb-1 blkclr f-14 fw-500">Strike</p><p className="mb-0 themeclr f-14 fw-500">70,250.0</p></div>
                                                    <div><p className="mb-1 blkclr f-14 fw-500">24h Change</p><p className="mb-0 f-14 redclr fw-500">-860.0 (-58.30%)</p></div>
                                                    <div><p className="mb-1 blkclr f-14 fw-500">24h Volume(Cont/USDT)</p><p className="mb-0 f-14 themeclr fw-500">20.15/16,822.65</p></div>
                                                    <div><p className="mb-1 blkclr f-14 fw-500">Open (Cont/USDT)</p><p className="mb-0 f-14 themeclr fw-500">10.78/753,444.00</p></div>
                                                    <div><p className="mb-1 blkclr f-14 fw-500">Time to Expiry</p><p className="mb-0 f-14 themeclr fw-500">19:15:13</p></div>
                                                    <div><p className="mb-1 blkclr f-14 fw-500">IV (Bid/Mark/Ask)</p><p className="mb-0 f-14 themeclr fw-500">52.93%/54.31%/55.70%</p></div>
                                                    <div><p className="mb-1 blkclr f-14 fw-500">Delta</p><p className="mb-0 f-14 themeclr fw-500">0.39218940</p></div>
                                                    <div><p className="mb-1 blkclr f-14 fw-500">Gamma</p><p className="mb-0 f-14 themeclr fw-500">0.00021870</p></div>
                                                    <div><p className="mb-1 blkclr f-14 fw-500">Theta</p><p className="mb-0 f-14 themeclr fw-500">-419.66483180</p></div>
                                                    <div><p className="mb-1 blkclr f-14 fw-500">Vega</p><p className="mb-0 f-14 themeclr fw-500">12.63357467</p></div>


                                                </div>
                                            </div>
                                        </div>

                                    </div>



                                    <div className="optgridbox">
                                        <div className="optgridboxone">
                                            <Symbolmodal />
                                        </div>
                                        <div className="optgridboxtwo">
                                            <Tradingview />
                                        </div>
                                        <div className="optgridboxthree">
                                            <Orderbooktable />
                                        </div>
                                        <div className="optgridboxfour">
                                            <CoinPairPrice optiontwo="optionpagetwo" />
                                        </div>
                                    </div>

                                </>
                            }
                        </div>

                        {candleTab != "candles" &&
                            <div className="option_border mt-4">
                                <h5 className="themeclr ms-3 mt-3">Recent Trades (USDT Options)</h5>
                                <div className="row mx-2 mt-4">
                                    <div className="col-lg-6">
                                        <div className="d-flex justify-content-between"><h5 className="mb-0 w-100 text-center">Calls</h5><p className="mb-0 nowrap f-15">24h Call Volume: 829.06</p></div>
                                        <Recentcallstable />
                                    </div>
                                    <div className="col-lg-6 mt-3 mt-lg-0">
                                        <div className="d-flex justify-content-between"><p className="mb-0 nowrap f-15">24h Call Volume: 829.06</p><h5 className="mb-0 w-100 text-center">Puts</h5></div>
                                        <Recentputstable />
                                    </div>
                                </div>
                            </div>}

                        <div className="mt-3">
                            <div className='netborder py-3'>
                                <div className='d-block d-xl-flex justify-content-between align-items-center'>
                                    <ul className='table_tabs overauto position mt-1 mb-2 mb-xl-0 mx-2 mx-lg-0'>
                                        <li className={positiontabs === "position" ? "active" : ""} onClick={() => setPositiontabs("position")}>Position (1)</li>
                                        <li className={positiontabs === "openorder" ? "active" : ""} onClick={() => setPositiontabs("openorder")}>Open Orders (1)</li>
                                        <li className={positiontabs === "orderhistory" ? "active" : ""} onClick={() => setPositiontabs("orderhistory")}>Order History (1)</li>
                                        <li className={positiontabs === "tradehistory" ? "active" : ""} onClick={() => setPositiontabs("tradehistory")}>Trade History (0)</li>
                                        <li className={positiontabs === "exercisedhistory" ? "active" : ""} onClick={() => setPositiontabs("exercisedhistory")}>Exercised History (0)</li>
                                        <li className={positiontabs === "transactionhistory" ? "active" : ""} onClick={() => setPositiontabs("transactionhistory")}>Transaction History (0)</li>
                                    </ul>
                                    <div class="form-check hidecheck flright pe-3"><input class="form-check-input" type="checkbox" id="flexCheckDefault" value="" /><label class="form-check-label fw-500" for="flexCheckDefault">Hide other underlying</label></div>
                                </div>

                                <div>
                                    {positiontabs === "position" &&
                                        <div className="mx-2">
                                            <Position />
                                        </div>
                                    }
                                    {positiontabs === "openorder" &&
                                        <div className="mx-2">
                                            <Openorders />
                                        </div>
                                    }
                                    {positiontabs === "orderhistory" &&
                                        <div className="mx-2">
                                            <Orderhistory/>
                                        </div>
                                    }
                                      {positiontabs === "tradehistory" &&
                                        <div className="mx-2">
                                            <Orderhistory/>
                                        </div>
                                    }
                                      {positiontabs === "exercisedhistory" &&
                                        <div className="mx-2">
                                            <Orderhistory/>
                                        </div>
                                    }
                                     {positiontabs === "transactionhistory" &&
                                        <div className="mx-2">
                                            <Orderhistory/>
                                        </div>
                                    }

                                </div>


                            </div>



                        </div>


                    </Container>
                </Container>

                <Footer />
            </div >
        </>
    )
}

export default Optionpage;