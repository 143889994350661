import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Table, InputGroup } from "react-bootstrap";
import Images from "../../Components/Images";
import { GoPlus } from "react-icons/go";
import { Link, useLocation } from "react-router-dom";
import { FiMinus } from "react-icons/fi";
import { GoMail } from "react-icons/go";

const Blockchainsubmission = (props) => {
    const location = useLocation();
    console.log(location, "asasda");
    const [filterRows, setFilterRows] = useState([{ filters: "" }]);
    const addFilters = () => {
        setFilterRows([...filterRows, { filters: "" }]);
    };
    const removeFilters = (index) => {
        const list = [...filterRows]
        list.splice(index, 1)
        setFilterRows(list)
    };

    const [filterRows1, setFilterRows1] = useState([{ filters: "" }]);
    const addFilters1 = () => {
        setFilterRows1([...filterRows1, { filters: "" }]);
    };
    const removeFilters1 = (index) => {
        const list = [...filterRows1]
        list.splice(index, 1)
        setFilterRows1(list)
    };
    return (
        <>
            <Form.Label>Blockchain Deployment date<span className="text-danger">*</span></Form.Label>
            <InputGroup className="mb-4 align-items-start topinputgroup">
                <InputGroup.Text><GoMail /></InputGroup.Text>
                <Form.Control as="textarea" className="form-control" placeholder=""></Form.Control>
            </InputGroup>
            <Form.Label>Purpose<span className="text-danger">*</span></Form.Label>
            <InputGroup className="mb-4 align-items-start topinputgroup">
                <InputGroup.Text><GoMail /></InputGroup.Text>
                <Form.Control as="textarea" placeholder=""></Form.Control>
            </InputGroup>
            {props.path == '/exchange' ?
            <Form.Group className="mb-4">
                <Form.Label>Trading type <span className="text-danger">*</span></Form.Label>
                <Form.Select className="form-control">
                    <option value="1">Order book </option>
                    <option value="2">AMM </option>
                    <option value="3">Other</option>
                </Form.Select>
            </Form.Group>:<></>}
            <Form.Group>
                <Form.Label>Unique Features</Form.Label>
                {filterRows.map((index, id) => (
                    <div className="d-flex align-items-center gap-1">
                        <Form.Control as="textarea" className="borderin mb-3" placeholder="Unique features....."></Form.Control>
                        <span className="add mt-0" onClick={removeFilters}><FiMinus /></span></div>
                ))}
                <span className="add" onClick={addFilters}><GoPlus /></span>
            </Form.Group>
            <Form.Group>
                <Form.Label>Use Case</Form.Label>
                {filterRows1.map((index, id) => (
                    <div className="d-flex align-items-center gap-1">
                        <Form.Control as="textarea" className="borderin mb-3" placeholder="Use Case....."></Form.Control>
                        <span className="add mt-0" onClick={removeFilters1}><FiMinus /></span></div>
                ))}
                <span className="add" onClick={addFilters1}><GoPlus /></span>
            </Form.Group>
        </>

    )
}

export default Blockchainsubmission;