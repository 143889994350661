import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Table } from "react-bootstrap";
import Images from "../../Components/Images";
import { GoPlus } from "react-icons/go";
import { Link } from "react-router-dom";
import { FiMinus } from "react-icons/fi";

const Socialmedia = () => {


    const [filterRowss, setFilterRowss] = useState([{ filters: "" }]);
    const addFilterss = () => {
        setFilterRowss([...filterRowss, { filters: "" }]);
    };
    const removeFilterss = (index) => {
        const list = [...filterRowss]
        list.splice(index, 1)
        setFilterRowss(list)
    };

    return (
        <>
            <div className="text-center">
                <h6 className="formhead">Social Media Links</h6>
                <p className="content">
                    Lorem ipsum dolor sit amet consectetur. Tortor id curabitur nisi a et dis a. Feugiat.
                </p>
            </div>
            <Form>
                <Row className="justify-content-between">
                    <Col xs={12} md={12} lg={4} xl={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Bitcointalk</Form.Label>
                            <Form.Control type="text" placeholder="Link"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>LinkedIn</Form.Label>
                            <Form.Control type="text" placeholder="Link"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Twitter</Form.Label>
                            <Form.Control type="text" placeholder="Link"></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={12} lg={4} xl={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Facebook</Form.Label>
                            <Form.Control type="text" placeholder="Link"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Medium</Form.Label>
                            <Form.Control type="text" placeholder="Link"></Form.Control>
                        </Form.Group>
                        <Form.Group className="mb-4 mb-lg-5">
                            <Form.Label>Telegram</Form.Label>
                            <Form.Control type="text" placeholder="Link"></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={12} lg={4} xl={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Instagram</Form.Label>
                            <Form.Control type="text" placeholder="Link"></Form.Control>
                        </Form.Group>

                        {/* <Form.Group className="mb-3">
                            <Form.Label>others</Form.Label>
                            <Form.Control type="text" placeholder="Link"></Form.Control>
                        </Form.Group> */}
                        <Form.Group className="mb-3">
                            <div className="flexbox justify-content-between flex-row" onClick={addFilterss}>
                                <div>
                                    <button type="button" className="btn anchorlink text-decoration-underline">
                                        <b>Click to add</b>
                                    </button>
                                </div>
                                <div>
                                    <span className="add mt-0"><GoPlus /></span>
                                </div>
                            </div>
                        </Form.Group>
                        {filterRowss.map((index, id) => (
                            <div className="d-flex gap-1 align-items-center">
                        <Form.Group className="mb-3 w-100">
                            <Form.Label>others</Form.Label>
                            <Form.Control type="text" placeholder="Link"></Form.Control>
                        </Form.Group>
                        <span className="add mt-0" onClick={removeFilterss}><FiMinus /></span>
                        </div>
                        ))}
                    </Col>
                </Row>
            </Form>


        </>
    )
}

export default Socialmedia;