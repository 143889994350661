import React, { useState, useEffect } from "react";
import Innerheader from "../Layouts/innerheader";
import Footer from "../Layouts/footer";
import { Accordion, Col, Container, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem, Table } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import Announcement from "../Components/announcement";
import { MdKeyboardArrowDown } from "react-icons/md";
import Orderbooktable from "../Components/orderbooktable";
import CoinPairPrice from "./CoinPairPrice";
import Syntheticsbook from "../Components/Synthetics/synthetics_book";
import Syntheticstrade from "../Components/Synthetics/synthetics_trade";
import Buysell from "../Components/Synthetics/buysell";
import Tableposition from "../Components/Synthetics/table_position";
import Tradingview from "../Components/Chartss/tradingview";
import { IoSend } from "react-icons/io5";
import Walletpage from "../Components/Modals/walletmodal";
import Withdrawmodals from "../Components/Synthetics/withdrawmodal";
import Depositmodals from "../Components/Synthetics/depositmodal";
import Transfermodals from "../Components/Synthetics/tranfermodals";
import Leveragetarget from "../Components/Synthetics/leveragetarget";
import Swal from 'sweetalert2';

function Synthetics() {

    useEffect(() => {
        Swal.fire({
            title: "Welcome to Synthetics Trading!",
            text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
          });
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    const [Walletmodal, setWalletmodal] = useState(false);
    const [booktab, setBooktab] = useState("book");
    // const [margintab, setMargintab] = useState("cross");
    const [selectedOption, setSelectedOption] = useState('Cross');
    const [selectedOption1, setSelectedOption1] = useState('Cross');
    // const handleSelect = (eventKey) => {
    //     setSelectedOption(eventKey);
    // };
    const [bstab, setBstab] = useState("buy");
    const [syntheicstable, setSyntheicstable] = useState("position");
    const [synwithdrawmodal, setSynwithdrawmodal] = useState(false);
    const [syndepositmodal, setSyndepositmodal] = useState(false);
    const [syntransfermodal, setSyntransfermodal] = useState(false);
    const [synleveragemodal, setSynleveragemodal] = useState(false);

    const [selectmarket, setSelectmarket] = useState({ id: 1, netimg: Images.eth, netname: "ETH - USD", multi: "20x", price: "$244.5", hr: "6.02%", vol: "$456M", int: "$23.4M" })
    const [isDropdownmarketOpen, setIsDropdownmarketOpen] = useState(false);
    const handleSelectmarket = (e) => {
        setSelectmarket(e);
        setIsDropdownmarketOpen(false); // Close the dropdown after selecting an item
    }; 
    const [marketlist, setMarketlist] = useState([
        { id: 1, netimg: Images.eth, netname: "ETH - USD", multi: "20x", price: "$244.5", hr: "6.02%", vol: "$456M", int: "$23.4M" },
        { id: 2, netimg: Images.btc, netname: "BTC - USD", multi: "10x", price: "$544.5", hr: "6.02%", vol: "$456M", int: "$23.4M" },
        { id: 3, netimg: Images.snx, netname: "SNX - USD", multi: "8x", price: "$484.5", hr: "6.02%", vol: "$456M", int: "$23.4M" },
        { id: 4, netimg: Images.avalan, netname: "AVA - USD", multi: "8x", price: "$297.5", hr: "6.02%", vol: "$456M", int: "$23.4M" },
    ])

    return (

        <>
            {Walletmodal && <Walletpage onDismiss={() => setWalletmodal(false)} />}
            {synwithdrawmodal && <Withdrawmodals onDismiss={() => setSynwithdrawmodal(false)} />}
            {syndepositmodal && <Depositmodals onDismiss={() => setSyndepositmodal(false)} />}
            {syntransfermodal && <Transfermodals onDismiss={() => setSyntransfermodal(false)} />}
            {synleveragemodal && <Leveragetarget onDismiss={() => setSynleveragemodal(false)} />}

            <div>
                <Innerheader />
                <Container fluid className="innerpage pools_sec assetpage px-0 pos">

                    <Container className="pt-0 container_max">
                    <h3 className="themeclr fw-600 text-center mt-3 mb-3">Synthetics Trading</h3>
                        {/* <Announcement /> */}
                        <img src={Images.backlogo} className='backlogo farms__backlogo' />
                        <div className="mb-3">
                            <div className="netborder px-2 px-lg-3 mx-1 mt-0 py-2">
                                <div className="d-block d-sm-flex align-items-center">
                                    <div>
                                        <Dropdown className="market marketed ms-sm-2" show={isDropdownmarketOpen} onToggle={() => setIsDropdownmarketOpen(!isDropdownmarketOpen)}>
                                            <DropdownToggle className="ps-0 d-flex align-items-center" onClick={() => setIsDropdownmarketOpen(!isDropdownmarketOpen)}><h5 className="themeclr mb-0"><img src={selectmarket.netimg} className="ethimg" /> {selectmarket.netname} <span className="orgclr f-13">{selectmarket.multi}</span> <MdKeyboardArrowDown /></h5></DropdownToggle>
                                            <DropdownMenu>
                                                <div>
                                                    <div class="pos mb-2">
                                                        <input type="search" class="form-control token_input" placeholder="e.g ETH or Ethereum" />
                                                        <img src={Images.searchicon} class="img-fluid search" />
                                                    </div>
                                                    <div class="marginlisttop">
                                                        <div class="d-flex marginlist gap-2 tab_list align-items-center py-2 px-1">
                                                            <button className="btn btn-brand-1 hover-up">All</button>
                                                            <button className="btn btn-brand-1 hover-up">Recently Listed</button>
                                                            <button className="btn btn-brand-1 hover-up">Layer 1</button>
                                                            <button className="btn btn-brand-1 hover-up">Layer 2</button>
                                                            <button className="btn btn-brand-1 hover-up">Defi</button>
                                                            <button className="btn btn-brand-1 hover-up">NFT</button>
                                                        </div>
                                                    </div>

                                                    <div className="price_table w-100 overauto">
                                                        <div className="table-responsive">
                                                            <Table responsive>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Market</th>
                                                                        <th>Price</th>
                                                                        <th>24h</th>
                                                                        <th>Volume</th>
                                                                        <th>Open Interest</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {marketlist.map((e, i) =>
                                                                        <tr className="cursor hovers" onClick={() => handleSelectmarket(e)}>
                                                                            <td><img src={e.netimg} className="ethimg" /> {e.netname} <span className="orgclr">{e.multi}</span></td>
                                                                            <td>{e.price}</td>
                                                                            <td className="grnclr">{e.hr}</td>
                                                                            <td>{e.vol}</td>
                                                                            <td>{e.int}</td>
                                                                        </tr>)}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </div>


                                                </div>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    <div className="marginlisttop">
                                        <div className="d-flex marginlist align-items-center py-2 px-2">
                                            <h5 className="redclr mb-0">$615.0</h5>
                                            <div><p className="mb-1 blkclr f-14 fw-500">Oracle Price</p><p className="mb-0 themeclr f-14 fw-500">27,582.07</p></div>
                                            <div><p className="mb-1 blkclr f-14 fw-500">24h Change</p><p className="mb-0 f-14 redclr fw-500">-860.0 (-58.30%)</p></div>
                                            <div><p className="mb-1 blkclr f-14 fw-500">Open Interest</p><p className="mb-0 themeclr f-14 fw-500">27,582.07</p></div>
                                            <div><p className="mb-1 blkclr f-14 fw-500">1hr Funding</p><p className="mb-0 themeclr f-14 fw-500">0.001548%</p></div>
                                            <div><p className="mb-1 blkclr f-14 fw-500">24h Volume</p><p className="mb-0 f-14 themeclr fw-500">16,822.65</p></div>
                                            <div><p className="mb-1 blkclr f-14 fw-500">24h Trades</p><p className="mb-0 f-14 themeclr fw-500">16,822.65</p></div>
                                            <div><p className="mb-1 blkclr f-14 fw-500">Next Funding</p><p className="mb-0 f-14 themeclr fw-500">16,822.65</p></div>
                                            <div><p className="mb-1 blkclr f-14 fw-500">Maximum Leverage</p><p className="mb-0 f-14 themeclr fw-500">20.00X</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div></div>

                        <div className="allsynbox">
                            <div className="pos synbox1">
                                <Tradingview />
                            </div>

                            <div className="synbox2">
                                <ul className="table_tabs ps-0">
                                    <li className={booktab == "book" ? "active" : ""} onClick={() => setBooktab("book")}>Book</li>
                                    <li className={booktab == "trades" ? "active" : ""} onClick={() => setBooktab("trades")}>Trade</li>
                                </ul>
                                {booktab == "book" &&
                                    <div className="px-2 h_set">
                                        <Syntheticsbook />
                                    </div>
                                }
                                {booktab == "trades" &&
                                    <div className="px-2 h_set">
                                        <Syntheticstrade />
                                    </div>
                                }
                            </div>

                            <div className="synbox3">
                                <div className="netborder h-100 p-3 p-lg-2 p-xl-3">
                                    {localStorage.getItem("metamask") !== "true" ?
                                        <div className="text-center mb-3"><button class="btn btn-brand-1 sitebtn hover-up" onClick={() => { setWalletmodal(true) }}>Connect Wallet</button></div> :
                                        <div className="d-flex justify-content-center gap-2 mb-3">
                                            <button class="btn btn-brand-1 sitebtn hover-up" onClick={() => setSynwithdrawmodal(true)}>Withdraw</button>
                                            <button class="btn btn-brand-1 sitebtn hover-up" onClick={() => setSyndepositmodal(true)}>Deposit</button>
                                            <button class="btn btn-brand-1 sitebtn hover-up" onClick={() => setSyntransfermodal(true)}><IoSend /></button>
                                        </div>}

                                    <div className="d-block text-center d-sm-flex align-items-center justify-content-between">
                                        <Dropdown className="ms-sm-2 mob-drop">
                                            <DropdownToggle className="d-flex align-items-center"><h5 className="themeclr mb-0">{selectedOption}</h5></DropdownToggle>
                                            <DropdownMenu className="py-2 px-1">
                                                <DropdownItem onClick={() => setSelectedOption("Cross")}>Cross <p className="mb-0 f-13 textwrap">(Use shared collateral and risk across multiple positions)</p></DropdownItem>
                                                <DropdownItem onClick={() => setSelectedOption("Isolated")} className="mt-3">Isolated <p className="mb-0 f-13 textwrap">(Use distinct collateral for each position to isolate risk.)</p></DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                        {selectedOption == "Isolated" &&
                                            <>
                                                <button className="btn btn-brand-1 sitebtn hover-up" onClick={() => setSynleveragemodal(true)}>1.00X</button>
                                            </>
                                        }
                                        <ul className="d-flex buyselltab mb-0 mt-2 mt-sm-0">
                                            <li className={bstab == "buy" ? "active" : ""} onClick={() => setBstab("buy")}>Buy</li>
                                            <li className={bstab == "sell" ? "active" : ""} onClick={() => setBstab("sell")}>Sell</li>
                                        </ul>
                                    </div>

                                    {bstab == "buy" &&
                                        <>
                                            <Buysell  tab={"buy"}/>
                                        </>
                                    }
                                    {bstab == "sell" &&
                                        <>
                                            <Buysell tab={"sell"}/>
                                        </>
                                    }
                                </div></div>

                            <div className="synbox4">
                                <div className="netborder py-3 h-100">
                                    <div className="d-flex justify-content-end mb-3 me-2">
                                        <Dropdown className="ms-sm-2 mob-drop">
                                            <DropdownToggle className="d-flex align-items-center"><h5 className="themeclr mb-0">{selectedOption1}</h5></DropdownToggle>
                                            <DropdownMenu className="py-2 px-1">
                                                <DropdownItem onClick={() => setSelectedOption1("Cross")}>Cross <p className="mb-0 f-13 textwrap">(Use shared collateral and risk across multiple positions)</p></DropdownItem>
                                                <DropdownItem onClick={() => setSelectedOption1("Isolated")} className="mt-3">Isolated <p className="mb-0 f-13 textwrap">(Use distinct collateral for each position to isolate risk.)</p></DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    <ul className="table_tabs overauto position mt-1 mb-2 mb-xl-0 mx-2 mx-lg-0">
                                        <li className={syntheicstable == "position" ? "active" : ""} onClick={() => setSyntheicstable("position")}>Position</li>
                                        <li className={syntheicstable == "order" ? "active" : ""} onClick={() => setSyntheicstable("order")}>Order</li>
                                        <li className={syntheicstable == "fill" ? "active" : ""} onClick={() => setSyntheicstable("fill")}>Fill</li>
                                    </ul>

                                    {syntheicstable == "position" &&
                                        <div className="mx-2">
                                            <Tableposition />
                                        </div>
                                    }
                                    {syntheicstable == "order" &&
                                        <div className="mx-2">
                                            <Tableposition />
                                        </div>
                                    }
                                    {syntheicstable == "fill" &&
                                        <div className="mx-2">
                                            <Tableposition />
                                        </div>
                                    }
                                </div></div>
                        </div>



                    </Container>
                </Container>

                <Footer />
            </div>


        </>

    )
}

export default Synthetics;