import React, { useState, useEffect } from "react";
import { Form, Table, InputGroup } from "react-bootstrap";
import "../Karthick.css";
import "../inner.css";
import Images from "../Components/Images";
import { FaSort } from "react-icons/fa";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { IoSearchOutline } from "react-icons/io5";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaLink } from "react-icons/fa6";


const CoinPairPrice = (props) => {

    const { cointab, mtrade, optiontwo } = props;

    const [coinlists, setCoinlists] = useState([
        {
            id: 1, image: Images.star, pair: "BTC", pairincr: "5x", price: "0.00444", change: "0.01", color: "green", coin: "USDT"
        },
        {
            id: 2, image: Images.star, pair: "ADA", price: "0.00943", change: "0.01", color: "red", coin: "BNB"
        },
        {
            id: 3, image: Images.star, pair: "AAVE", price: "0.00943", change: "0.01", color: "green", coin: "USDT"
        },
        {
            id: 4, image: Images.star, pair: "1INCH", pairincr: "3x", price: "0.00214", change: "0.01", color: "green", coin: "USDT"
        },
        {
            id: 5, image: Images.star, pair: "AKRO", price: "0.00543", change: "0.01", color: "red", coin: "BNB"
        },
        {
            id: 6, image: Images.star, pair: "ALGO", pairincr: "10x", price: "0.00214", change: "0.01", color: "red", coin: "USDT"
        },
        {
            id: 7, image: Images.star, pair: "BTC", price: "0.00543", change: "0.01", color: "green", coin: "BNB"
        },
        {
            id: 8, image: Images.star, pair: "ALICE", price: "0.002343", change: "0.01", color: "green", coin: "USDT"
        },
        {
            id: 9, image: Images.star, pair: "ADA", price: "0.00673", change: "0.01", color: "green", coin: "BNB"
        },
        {
            id: 10, image: Images.star, pair: "ALGO", price: "0.00433", change: "0.01", color: "green", coin: "USDT"
        },
        {
            id: 11, image: Images.star, pair: "ALGO", price: "0.00754", change: "0.01", color: "green", coin: "BTC"
        },
        {
            id: 12, image: Images.star, pair: "ADA", price: "0.00243", change: "0.01", color: "green", coin: "BTC"
        },
        {
            id: 13, image: Images.star, pair: "AAVE", price: "0.00883", change: "0.01", color: "red", coin: "BTC"
        },
        {
            id: 14, image: Images.star, pair: "ACM", pairincr: "3x", price: "0.00543", change: "0.01", color: "green", coin: "BTC"
        },
        {
            id: 15, image: Images.star, pair: "ALGO", price: "0.00543", change: "0.01", color: "green", coin: "FIAT"
        },
        {
            id: 16, image: Images.star, pair: "ACM", price: "0.00543", change: "0.01", color: "green", coin: "FIAT"
        },
        {
            id: 17, image: Images.star, pair: "ACM", pairincr: "3x", price: "0.00543", change: "0.01", color: "green", coin: "USDC"
        },
        {
            id: 18, image: Images.star, pair: "ADA", price: "0.00223", change: "0.01", color: "red", coin: "USDC"
        },
        {
            id: 19, image: Images.star, pair: "AKRO", pairincr: "3x", price: "0.00243", change: "0.01", color: "green", coin: "TUSD"
        },
        {
            id: 20, image: Images.star, pair: "ADA", price: "0.00213", change: "0.01", color: "red", coin: "TUSD"
        },
        {
            id: 21, image: Images.star, pair: "BTC", pairincr: "3x", price: "0.00243", change: "0.01", color: "red", coin: "FDUSD"
        },
        {
            id: 22, image: Images.star, pair: "ALGO", price: "0.00213", change: "0.01", color: "red", coin: "FDUSD"
        },
        {
            id: 23, image: Images.star, pair: "BTC", price: "0.00243", change: "0.01", color: "green", coin: "ALTS"
        },
        {
            id: 24, image: Images.star, pair: "ADA", price: "0.00213", change: "0.01", color: "green", coin: "ALTS"
        },
        {
            id: 25, image: Images.star, pair: "AAVE", pairincr: "5x", price: "0.00243", change: "0.01", color: "green", coin: "ETH"
        },
        {
            id: 26, image: Images.star, pair: "ALGO", price: "0.00213", change: "0.01", color: "red", coin: "ETH"
        },
        {
            id: 27, image: Images.star, pair: "ALPHA", pairincr: "10x", price: "0.00643", change: "0.01", color: "green", coin: "DAI"
        },
        {
            id: 28, image: Images.star, pair: "ANT", price: "0.00203", change: "0.01", color: "green", coin: "DAI"
        },
        {
            id: 29, image: Images.star, pair: "ACM", pairincr: "3x", price: "0.00873", change: "0.01", color: "green", coin: "XRP"
        },
        {
            id: 30, image: Images.star, pair: "ARDR", price: "0.00663", change: "0.01", color: "red", coin: "XRP"
        },
        {
            id: 31, image: Images.star, pair: "ARDR", price: "0.00663", change: "0.01", color: "red", coin: "USDT"
        },
        {
            id: 32, image: Images.star, pair: "ARDR", price: "0.00663", change: "0.01", color: "red", coin: "USDT"
        },
        {
            id: 33, image: Images.star, pair: "ALPHA", pairincr: "10x", price: "0.00643", change: "0.01", color: "green", coin: "USDT"
        },
        {
            id: 34, image: Images.star, pair: "AKRO", price: "0.00543", change: "0.01", color: "red", coin: "USDT"
        },
        {
            id: 35, image: Images.star, pair: "ALPHA", price: "0.00543", change: "0.01", color: "red", coin: "USDT"
        },
        {
            id: 36, image: Images.star, pair: "ALICE", price: "0.002343", change: "0.01", color: "green", coin: "USDT"
        },
        {
            id: 37, image: Images.star, pair: "ALICE", price: "0.002343", change: "0.01", color: "green", coin: "USDT"
        },
        {
            id: 38, image: Images.star, pair: "ADA", price: "0.00223", change: "0.01", color: "red", coin: "USDC"
        },
        {
            id: 39, image: Images.star, pair: "ADA", price: "0.00223", change: "0.01", color: "red", coin: "USDC"
        },
        {
            id: 40, image: Images.star, pair: "ADA", price: "0.00223", change: "0.01", color: "red", coin: "USDC"
        },
        {
            id: 41, image: Images.star, pair: "ADA", price: "0.00223", change: "0.01", color: "red", coin: "USDC"
        },
        {
            id: 42, image: Images.star, pair: "AAVE", price: "0.00943", change: "0.01", color: "green", coin: "USDT"
        },
        {
            id: 43, image: Images.star, pair: "AAVE", price: "0.00943", change: "0.01", color: "green", coin: "USDT"
        },
        {
            id: 44, image: Images.star, pair: "ADA", price: "0.00243", change: "0.01", color: "green", coin: "USDT"
        },
        {
            id: 45, image: Images.star, pair: "AAVE", price: "0.00943", change: "0.01", color: "green", coin: "USDT"
        },
    ]);

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [tabs, setTabs] = useState("USDT");
    const coins = ["USDT", "BNB", "BTC", "FIAT", "USDC", "TUSD", "FDUSD", "ALTS", "ETH", "DAI", "XRP"];
   
    const [searchcoin, setSearchcoin] = useState("");
    const changefunc = (e) => {
        var lowercase = e.target.value.toLowerCase();
        setSearchcoin(lowercase);
    }
    const tablecontent = coinlists.filter((cl) => {
        if(searchcoin === "") {
            return cl;
        } else {
            return cl.pair.toLowerCase().includes(searchcoin);
        }
    });

    const [tradebox, setTradebox] = useState("markettrade");
    const tradeboxhead = ["Price(USDT)", "Amount(BTC)", "Time"];
    const [tradeboxbody, setTradeboxbody] = useState([
        {
            price: "460.0",
            amount: "0.000160",
            Time: "10:41:35",
            color: "green"
        },
        {
            price: "460.0",
            amount: "0.000160",
            Time: "10:41:35",
            color: "red"
        },
        {
            price: "565.0",
            amount: "0.000445",
            Time: "10:42:36",
            color: "green"
        },
        {
            price: "565.0",
            amount: "0.000445",
            Time: "10:42:36",
            color: "green"
        },
        {
            price: "485.0",
            amount: "0.000525",
            Time: "10:43:25",
            color: "red"
        },
        {
            price: "565.0",
            amount: "0.000445",
            Time: "10:42:36",
            color: "green"
        },
        {
            price: "460.0",
            amount: "0.000160",
            Time: "10:41:35",
            color: "red"
        },
        {
            price: "565.0",
            amount: "0.000445",
            Time: "10:42:36",
            color: "green"
        },
        {
            price: "485.0",
            amount: "0.000525",
            Time: "10:43:25",
            color: "red"
        },
        {
            price: "485.0",
            amount: "0.000525",
            Time: "10:43:25",
            color: "green"
        },
        {
            price: "485.0",
            amount: "0.000525",
            Time: "10:43:25",
            color: "red"
        },
        {
            price: "565.0",
            amount: "0.000445",
            Time: "10:42:36",
            color: "green"
        },
        {
            price: "565.0",
            amount: "0.000445",
            Time: "10:42:36",
            color: "green"
        },
        {
            price: "565.0",
            amount: "0.000445",
            Time: "10:42:36",
            color: "green"
        },
        {
            price: "485.0",
            amount: "0.000525",
            Time: "10:43:25",
            color: "red"
        },
        {
            price: "485.0",
            amount: "0.000525",
            Time: "10:43:25",
            color: "red"
        },
    ]);

  return (
    <>
        <div className="coinpairprice">
            { cointab &&
                <div className="supplytable">
                    <Form>
                        <InputGroup>
                            <InputGroup.Text><IoSearchOutline /></InputGroup.Text>
                            <Form.Control type="text" onChange={changefunc}></Form.Control>
                        </InputGroup>
                    </Form>
                    <Box sx={{ maxWidth: { xs: 320, sm: 480 }}}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            { coins.map((co) => (
                                <Tab label={co} onClick={() => setTabs(co)}></Tab>
                                ))
                            }
                        </Tabs>
                    </Box>
                    <Table responsive className="pairpricetable">
                        <thead>
                            <tr>
                                <th>Pair <FaSort /></th>
                                <th>Price <FaSort /></th>
                                <th>Change <FaSort /></th>
                            </tr>
                        </thead>
                        <tbody className="t_body">
                            {tablecontent.map((e) =>
                                <>
                                    {e.coin == tabs &&      
                                     <OverlayTrigger
                                     placement="left"
                                     delay={{ show: 250, hide: 400 }}
                                     overlay={<Tooltip id="button-tooltip">
                                         $ 0.23137 <FaLink/>
                                     </Tooltip>}
                                 >                               
                                        <tr className="cursor hovertr">
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <img src={e.image} className="img-fluid wbox" />
                                                    <span className="themeclr ms-2">
                                                        {e.pair}/{e.coin == tabs && e.coin} { e.pairincr && <small className="pairincr">{e.pairincr}</small> }
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <p className={`themeclr mb-0 ${e.color == "green" ? "t-green" : "t-red"}`}>
                                                    {e.price}
                                                </p>
                                            </td>
                                            <td>
                                                <p className={`themeclr mb-0 ${e.color == "green" ? "t-green" : "t-red"}`}>
                                                    {e.change}
                                                </p>
                                            </td>
                                        </tr>    
                                        </OverlayTrigger>                                
                                    }
                                </>
                            )}
                        </tbody>
                    </Table>
                </div>
            }
            { mtrade &&
                <div className="supplytable">
                    <div className="tradetab">
                        <div className="mb-2">
                            <button type="button" className={tradebox == "markettrade" ? "active btn tradebtn" : "btn tradebtn"} onClick={() => setTradebox("markettrade")}>Market Trades</button>
                            <button type="button" className={tradebox == "mytrade" ? "active btn tradebtn" : "btn tradebtn"} onClick={() => setTradebox("mytrade")}>My Trades</button>
                        </div>

                        { tradebox == "markettrade" && 
                            <Table responsive className="pairpricetable">
                                <thead>
                                    <tr>
                                        { tradeboxhead.map((th) => (
                                            <th>{th}</th>
                                        )) }
                                    </tr>
                                </thead>
                                <tbody className="t_body">
                                    { tradeboxbody.map((td) => (
                                        <tr>
                                            <td>
                                                <span className={td.color == "green" ? "t-green" : "t-red"}>{td.price}</span>
                                            </td>
                                            <td>{td.amount}</td>
                                            <td>{td.Time}</td>
                                        </tr>
                                    )) }
                                </tbody>
                            </Table>
                        }

                        { tradebox == "mytrade" && 
                            <Table responsive className="pairpricetable">
                                <thead>
                                    <tr>
                                        { tradeboxhead.map((th) => (
                                            <th>{th}</th>
                                        )) }
                                    </tr>
                                </thead>
                                <tbody className="t_body">
                                    { tradeboxbody.map((td) => (
                                        <tr>
                                            <td>
                                                <span className={td.color == "green" ? "t-green" : "t-red"}>{td.price}</span>
                                            </td>
                                            <td>{td.amount}</td>
                                            <td>{td.Time}</td>
                                        </tr>
                                    )) }
                                </tbody>
                            </Table>
                        }      
                    </div>
                </div>
            }
            { optiontwo == "optionpagetwo" &&
                <> 
                    <h6 className="mb-3 fw-600">My Trades</h6>
                    <div className="supplytable">
                        <Table responsive className="pairpricetable bookslist mb-0">
                            <thead>
                                <tr>
                                    { tradeboxhead.map((th) => (
                                        <th>{th}</th>
                                    )) }
                                </tr>
                            </thead>
                            <tbody className="t_body">
                                { tradeboxbody.map((td) => (
                                    <tr>
                                        <td>
                                            <span className={td.color == "green" ? "t-green" : "t-red"}>{td.price}</span>
                                        </td>
                                        <td>{td.amount}</td>
                                        <td>{td.Time}</td>
                                    </tr>
                                )) }
                            </tbody>
                        </Table>
                    </div>
                </>                
            }   
        </div>
    </>
  )
}

export default CoinPairPrice;