import React, { useState, useEffect } from "react";
import { Modal, Col, Container, InputGroup, DropdownButton, Form, Dropdown, Row, DropdownToggle, DropdownMenu, DropdownItem } from "react-bootstrap";
import Images from "../Components/Images";
import Switch from "react-switch";
import { Slider } from 'rsuite';
// import "rsuite/dist/rsuite.min.css";
import 'rsuite/dist/rsuite-no-reset.min.css';
import { MdKeyboardArrowDown } from "react-icons/md";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Transfermodals from "./Modals/transfermodal"
import { Link } from "react-router-dom";
import Marginlevelmodals from "./Modals/marginlevelmodal";
import Crossborrowmodals from "./Modals/crossborrowmodal";
import { BsFillInfoCircleFill } from "react-icons/bs";
import Leveragemodals from "./Modals/leveragemodal";
import { IoMdArrowDropdown } from "react-icons/io";


function Spottab(props) {
    const [termssTab, setTermssTab] = useState('limit');
    // const handleTabClick = (tab) => {
    //     setTermssTab(tab);
    // };
    const [modesfirstTab, setModesfirstTab] = useState('normal');
    const handlemodefirstTabClick = (tab) => {
        setModesfirstTab(tab);
    };
    const [modessecondTab, setModessecondTab] = useState('normal');
    const handlemodesecondTabClick = (tab) => {
        setModessecondTab(tab);
    };
    // const [limitTab, setLimitTab] = useState('Stop limit');
    // const handlelimitTabClick = (tab) => {
    //     setLimitTab(tab);
    // };
    // { console.log("fsfs", limitTab); }


    const [value1, setValue1] = useState(5);
    const handleStyle1 = {
        color: '#fff',
        fontSize: 12,
        width: 32,
        height: 22
    };
    const labels1 = ['1x', '5x', '10x', '15x', '20x', '25x']; // Example labels

    const [value2, setValue2] = useState(5);
    const handleStyle2 = {
        color: '#fff',
        fontSize: 12,
        width: 32,
        height: 22
    };
    const labels2 = ['1x', '5x', '10x', '15x', '20x', '25x']; // Example labels



    const [tranfermodal, setTransfermodal] = useState(false);
    const [marginlevelmodal, setMarginlevelmodal] = useState(false);
    const [crossborrowmodal, setCrossborrowmodal] = useState(false);
    const [leveragemodal, setLeveragemodal] = useState(false);


    return (
        <>
            {tranfermodal && <Transfermodals onDismiss={() => setTransfermodal(false)} />}
            {marginlevelmodal && <Marginlevelmodals onDismiss={() => setMarginlevelmodal(false)} />}
            {crossborrowmodal && <Crossborrowmodals onDismiss={() => setCrossborrowmodal(false)} transferclick={() => { setCrossborrowmodal(false); setTransfermodal(true) }} />}
            {leveragemodal && <Leveragemodals onDismiss={() => setLeveragemodal(false)} />}
            <div>
                <div className="d-block d-xl-flex align-items-center justify-content-between pt-3 pb-3 mb-xl-0">
                    <ul className="listtab spotlisttab d-flex align-items-center gap-4 mb-3">
                        {/* <li className={termssTab === "limit" ? "active fw-600 ms-0" : "ms-0"} onClick={() => { handleTabClick('limit'); setLimitTab('Stop limit') }}>Limit</li>
                        <li className={termssTab === "market" ? "active fw-600" : ""} onClick={() => { handleTabClick('market'); setLimitTab('Stop limit') }}>Market</li>
                        <li className={termssTab === "Stop limit" ? "active fw-600" : ""} onClick={() => handleTabClick('Stop limit')}>{limitTab}</li>
                        <Dropdown>
                            <DropdownToggle className="py-0"></DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => setLimitTab('Stop limit')}>Stop Limit</DropdownItem>
                                <DropdownItem onClick={() => setLimitTab('Trailing Stop')}>Trailing Stop</DropdownItem>
                                <DropdownItem onClick={() => setLimitTab('OCO')}>OCO</DropdownItem>
                            </DropdownMenu>
                        </Dropdown> */}
                        <li className={termssTab === "limit" ? "active fw-600 ms-0" : "ms-0"} onClick={() => setTermssTab('limit')}>Limit</li>
                        <li className={termssTab === "market" ? "active fw-600" : ""} onClick={() => setTermssTab('market')}>Market</li>
                        <Dropdown className="drop_no">
                            <DropdownToggle className={(termssTab != "market") && (termssTab != "limit") ?"active fw-600 py-0":"py-0"}>{(termssTab == "market" || termssTab == "limit") ? "Stop Limit" : termssTab}</DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={() => setTermssTab('Stop limit')}>Stop Limit</DropdownItem>
                                {props.tab == "spot" && <DropdownItem onClick={() => setTermssTab('Stop Market')}>Stop Market</DropdownItem>}
                                <DropdownItem onClick={() => setTermssTab('Trailing Stop')}>Trailing Stop</DropdownItem>
                                <DropdownItem onClick={() => setTermssTab('OCO')}>OCO</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </ul>
                    <div className="d-flex gap-3 mb-3">
                        {props.tab == "spot" &&
                            <>
                                <p className="mb-0 themeclr link f-15 fw-600" onClick={() => setTransfermodal(true)}>Transfer |</p>
                                <Link to="/" target="_blank"><p className="mb-0 themeclr link f-15 fw-600">Auto Invest |</p></Link>
                                <Link to="/" target="_blank"><p className="mb-0 themeclr link f-15 fw-600">Buy with</p></Link>
                            </>
                        }
                        {props.tab == "cross" &&
                            <>
                                <p className="mb-0 themeclr link f-15 fw-600" onClick={() => setMarginlevelmodal(true)}>Low Risk <span className="grnclr">99.00</span> |</p>
                                <p className="mb-0 themeclr link f-15 fw-600" onClick={() => setTransfermodal(true)}>Transfer |</p>
                                <p className="mb-0 themeclr link f-15 fw-600" onClick={() => setCrossborrowmodal(true)}>Borrow |</p>
                            </>
                        }
                        {props.tab == "isolated" &&
                            <>
                                <p className="themeclr f-13 mb-0 ms-0 ms-xl-3 ms-xxl-0"><BsFillInfoCircleFill /> Transfer to activate this trading pair.</p>
                                <p className="mb-0 themeclr link f-15 fw-600" onClick={() => setLeveragemodal(true)}>Max 10</p>
                                <p className="mb-0 themeclr link f-15 fw-600" onClick={() => setTransfermodal(true)}>Transfer</p>
                                <p className="mb-0 themeclr link f-15 fw-600" onClick={() => setCrossborrowmodal(true)}>Borrow</p>
                            </>
                        }
                    </div>
                </div>

                <div>
                    <div className="row">
                        <div className="col-lg-6 mt-4 mt-lg-0">
                            {(props.tab == "cross") || (props.tab == "isolated") ?
                                <ul className="listtab modes d-flex gap-1 mt-2">
                                    <li className={modesfirstTab === "normal" ? "active fw-600 ms-0" : "ms-0"} onClick={() => handlemodefirstTabClick('normal')}>Normal</li>
                                    <li className={modesfirstTab === "borrows" ? "active fw-600" : ""} onClick={() => handlemodefirstTabClick('borrows')}>Borrow</li>
                                    <li className={modesfirstTab === "repay" ? "active fw-600" : ""} onClick={() => handlemodefirstTabClick('repay')}>Repay</li>
                                </ul> : <></>
                            }
                            <div className="d-flex gap-4">
                                <p className="themeclr mb-1 fw-500">Avbl - 0.00 BTC</p>
                                {modesfirstTab === "borrows" &&
                                    <p className="themeclr mb-1 fw-500">Max - 0.32 BTC</p>}
                            </div>
                            {(termssTab === "limit" || termssTab === "market") &&
                                <div className="pos">
                                    <input type="text" className="form-control input_spot text-end" disabled={termssTab === "market" ? true : false} placeholder="0.00" />
                                    <p className="p_pos blkclr mb-0 fw-500">Price</p>
                                    <p className="net_pos themeclr mb-0 fw-500 px-2">USDT</p>
                                </div>}

                            {termssTab === "Trailing Stop" &&
                                <>
                                    <div className="d-flex align-items-center gap-3 mb-3">
                                        <div className="pos w-100">
                                            <input type="text" className="form-control input_spot text-end" placeholder="00" />
                                            <p className="p_pos blkclr mb-0 fw-500">Trailing Delta</p>
                                            <p className="net_pos themeclr mb-0 fw-500 px-2">%</p>
                                        </div>
                                        <p className="mb-0 noactive">1%</p>
                                        <p className="mb-0 noactive">2%</p>
                                    </div>
                                </>
                            }

                            {termssTab === "OCO" &&
                                <div className="pos mb-3">
                                    <input type="text" className="form-control input_spot text-end" placeholder="0.00" />
                                    <p className="p_pos blkclr mb-0 fw-500">Price</p>
                                    <p className="net_pos themeclr mb-0 fw-500 px-2">USDT</p>
                                </div>}

                            {(termssTab === "Stop limit" || termssTab === "Stop Market" || termssTab === "OCO" || termssTab === "OCO") &&
                                <div className="pos">
                                    <input type="text" className="form-control input_spot text-end" placeholder="0.0" />
                                    <p className="p_pos blkclr mb-0 fw-500">Stop</p>
                                    <p className="net_pos themeclr mb-0 fw-500 px-2">USDT</p>
                                </div>
                            }

                            {(termssTab === "Stop limit" || termssTab === "Trailing Stop" || termssTab === "OCO") &&
                                <div className="pos mt-3">
                                    <input type="text" className="form-control input_spot text-end" placeholder="0.00" />
                                    <p className="p_pos blkclr mb-0 fw-500">Limit</p>
                                    <p className="net_pos themeclr mb-0 fw-500 px-2">BTC</p>
                                </div>
                            }

                            {termssTab === "Stop Market" &&
                                <div className="pos mt-3">
                                    <input type="text" className="form-control input_spot text-end" disabled placeholder="0.00" />
                                    <p className="p_pos blkclr mb-0 fw-500">Price</p>
                                    <p className="net_pos themeclr mb-0 fw-500 px-2">USDT</p>
                                </div>}

                            {(termssTab === "limit" || termssTab === "Stop limit" || termssTab === "Stop Market" || termssTab === "Trailing Stop" || termssTab === "OCO") &&
                                <div className="pos mt-3">
                                    <input type="text" className="form-control input_spot text-end" placeholder="0.00" />
                                    <p className="p_pos blkclr mb-0 fw-500">Amount</p>
                                    <p className="net_pos themeclr mb-0 fw-500 px-2">BTC</p>
                                </div>}


                            {termssTab === "market" &&
                                <>
                                    <InputGroup className="mb-3 mt-3 inputall pos">
                                        <DropdownButton
                                            title="Amount"
                                            id="input-group-dropdown-1">
                                            <Dropdown.Item>Amount</Dropdown.Item>
                                            <Dropdown.Item>Total</Dropdown.Item>
                                        </DropdownButton>
                                        <Form.Control className="form-control input_spot text-end" aria-label="Text input with dropdown button" />
                                        <p className="net_pos themeclr mb-0 fw-500 px-2">BTC</p>
                                    </InputGroup>
                                </>}
                            <div className="pt-3 pb-2 mx-2 mx-lg-1 mx-xl-2">
                                <Slider
                                    min={0}
                                    // max={labels1.length + 1}
                                    max={60}
                                    step={5}
                                    value={value1}
                                    className="custom-slider"
                                    handleStyle={handleStyle1}
                                    graduated
                                    progress
                                    onChange={(v) => setValue1(v)}
                                    renderMark={(mark) => {
                                        return mark + "x";
                                    }}
                                />
                            </div>
                            {(termssTab != "market") && (termssTab!= "Stop Market")  &&
                                <div className="pos mt-3">
                                    <input type="text" className="form-control input_spot text-end" placeholder="0.00" />
                                    <p className="p_pos blkclr mb-0 fw-500">Total</p>
                                    <p className="net_pos themeclr mb-0 fw-500 px-2">USDT</p>
                                </div>}
                            {props.tab == "spot" ?
                                <p className="mb-0 mt-1 blkclr fw-500">Max Buy <span className="themeclr">0 BTC</span></p> : ""}
                            <p className="mb-0 mt-1 blkclr fw-500">Est Fee</p>

                            {modesfirstTab === "borrows" &&
                                <p className="mb-2 mt-1 blkclr fw-500">Borrowing : <span className="themeclr">0 USDT</span></p>
                            }
                            {modesfirstTab === "repay" &&
                                <p className="mb-2 mt-1 blkclr fw-500">Repaying : <span className="themeclr">0 USDT</span></p>
                            }
                            <button className="btn buybtn w-100 fw-600 mt-2">  {modesfirstTab === "borrows" ? <span>Margin</span> : <></>} Buy BTC</button>
                        </div>


                        <div className="col-lg-6 mt-4 mt-lg-0">
                            {(props.tab == "cross") || (props.tab == "isolated") ?
                                <ul className="listtab modes d-flex gap-1 mt-2">
                                    <li className={modessecondTab === "normal" ? "active fw-600 ms-0" : "ms-0"} onClick={() => setModessecondTab('normal')}>Normal</li>
                                    <li className={modessecondTab === "borrows" ? "active fw-600" : ""} onClick={() => setModessecondTab('borrows')}>Borrow</li>
                                    <li className={modessecondTab === "repay" ? "active fw-600" : ""} onClick={() => setModessecondTab('repay')}>Repay</li>
                                </ul> : <></>
                            }
                            <div className="d-flex gap-4">
                                <p className="themeclr mb-1 fw-500">Avbl - 0.00 BTC</p>
                                {modessecondTab === "borrows" &&
                                    <p className="themeclr mb-1 fw-500">Max - 0.32 BTC</p>}
                            </div>
                            {(termssTab === "limit" || termssTab === "market") &&
                                <div className="pos">
                                    <input type="text" className="form-control input_spot text-end" placeholder="0.00" disabled={termssTab === "market" ? true : false} />
                                    <p className="p_pos blkclr mb-0 fw-500">Price</p>
                                    <p className="net_pos themeclr mb-0 fw-500 px-2">USDT</p>
                                </div>}
                            {termssTab === "Trailing Stop" &&
                                <>
                                    <div className="d-flex align-items-center gap-3 mb-3">
                                        <div className="pos w-100">
                                            <input type="text" className="form-control input_spot text-end" placeholder="00" />
                                            <p className="p_pos blkclr mb-0 fw-500">Trailing Delta</p>
                                            <p className="net_pos themeclr mb-0 fw-500 px-2">%</p>
                                        </div>
                                        <p className="mb-0 noactive">1%</p>
                                        <p className="mb-0 noactive">2%</p>
                                    </div>
                                </>
                            }
                            {termssTab === "OCO" &&
                                <div className="pos mb-3">
                                    <input type="text" className="form-control input_spot text-end" placeholder="0.00" />
                                    <p className="p_pos blkclr mb-0 fw-500">Price</p>
                                    <p className="net_pos themeclr mb-0 fw-500 px-2">USDT</p>
                                </div>}

                            {(termssTab === "Stop limit" || termssTab === "Stop Market" || termssTab === "OCO") &&
                                <div className="pos mb-0">
                                    <input type="text" className="form-control input_spot text-end" placeholder="0.0" />
                                    <p className="p_pos blkclr mb-0 fw-500">Stop</p>
                                    <p className="net_pos themeclr mb-0 fw-500 px-2">USDT</p>
                                </div>
                            }
                            {(termssTab === "Stop limit" || termssTab === "Trailing Stop" || termssTab === "OCO") &&
                                <div className="pos mt-3">
                                    <input type="text" className="form-control input_spot text-end" placeholder="0.00" />
                                    <p className="p_pos blkclr mb-0 fw-500">Limit</p>
                                    <p className="net_pos themeclr mb-0 fw-500 px-2">BTC</p>
                                </div>
                            }
                            {termssTab === "Stop Market" &&
                                <div className="pos mt-3">
                                    <input type="text" className="form-control input_spot text-end" disabled placeholder="0.00" />
                                    <p className="p_pos blkclr mb-0 fw-500">Price</p>
                                    <p className="net_pos themeclr mb-0 fw-500 px-2">USDT</p>
                                </div>}

                            {(termssTab === "limit" || termssTab === "Stop limit" || termssTab === "Stop Market" || termssTab === "Trailing Stop" || termssTab === "OCO") &&
                                <div className="pos mt-3">
                                    <input type="text" className="form-control input_spot text-end" placeholder="0.00" />
                                    <p className="p_pos blkclr mb-0 fw-500">Amount</p>
                                    <p className="net_pos themeclr mb-0 fw-500 px-2">BTC</p>
                                </div>}
                            {termssTab === "market" &&
                                <>
                                    <InputGroup className="mb-3 mt-3 inputall pos">
                                        <DropdownButton
                                            title="Amount"
                                            id="input-group-dropdown-1">
                                            <Dropdown.Item>Amount</Dropdown.Item>
                                            <Dropdown.Item>Total</Dropdown.Item>
                                        </DropdownButton>
                                        <Form.Control className="form-control input_spot text-end" aria-label="Text input with dropdown button" />
                                        <p className="net_pos themeclr mb-0 fw-500 px-2">BTC</p>
                                    </InputGroup></>
                            }
                            <div className="pt-3 pb-2 mx-2 mx-lg-1 mx-xl-2">
                                <Slider
                                    min={0}
                                    // max={labels2.length + 1}
                                    max={60}
                                    step={5}
                                    value={value2}
                                    className="custom-slider"
                                    handleStyle={handleStyle2}
                                    graduated
                                    progress
                                    onChange={(v) => setValue2(v)}
                                    renderMark={(mark) => {
                                        return mark + "x";
                                    }}
                                />
                            </div>
                            {(termssTab != "market") && (termssTab!= "Stop Market")  &&
                                <div className="pos mt-3">
                                    <input type="text" className="form-control input_spot text-end" placeholder="0.00" />
                                    <p className="p_pos blkclr mb-0 fw-500">Total</p>
                                    <p className="net_pos themeclr mb-0 fw-500 px-2">USDT</p>
                                </div>}
                            {props.tab == "spot" ? <p className="mb-0 mt-1 blkclr fw-500">Max Buy <span className="themeclr">0 USDT</span></p> : ""}
                            <p className="mb-0 mt-1 blkclr fw-500">Est Fee</p>
                            {modessecondTab === "borrows" &&
                                <p className="mb-2 mt-1 blkclr fw-500">Borrowing : <span className="themeclr">0 USDT</span></p>
                            }
                            {modessecondTab === "repay" &&
                                <p className="mb-2 mt-1 blkclr fw-500">Repaying : <span className="themeclr">0 USDT</span></p>
                            }
                            <button className="btn sellbtn w-100 fw-600 mt-2 mb-3">{modessecondTab === "borrows" ? <span>Margin</span> : <></>} Sell BTC</button>
                        </div>
                    </div>
                </div>


            </div>

        </>
    )
}

export default Spottab;