import React, { useState, useEffect } from "react";
import { Col, Container, Row, Form, Table, InputGroup } from "react-bootstrap";
import Images from "../../Components/Images";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const Trafficflow = () => {

    const [startDate, setStartDate] = useState(new Date("2024-08-01"));

    return (

        <>

<div className="text-center mb-4">
                                        <h6 class="formhead">TRAFFIC FLOW </h6>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl-4 col-xxl-4 pt-3">
                                            <h6 class="formhead text-capitalize">Daily Visits</h6>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Highest Number of Users in 24 hours </Form.Label>
                                                <Form.Control type="text" placeholder="highest number of users in 24 hours"></Form.Control>
                                                <Form.Control className="mt-2" type="text" placeholder="Supply url"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Lowest Trading Volume in 24 hours  </Form.Label>
                                                <Form.Control type="text" placeholder="lowest number of users in 24 hours"></Form.Control>
                                                <Form.Control className="mt-2" type="text" placeholder="Supply url"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Average Daily Users  </Form.Label>
                                                <Form.Control type="text" placeholder="average"></Form.Control>
                                            </Form.Group>
                                        </div>

                                        <div className="col-xl-4 col-xxl-4 pt-3">
                                            <h6 class="formhead text-capitalize">Weekly Visits</h6>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Highest Number of Users in 7 days </Form.Label>
                                                <Form.Control type="text" placeholder="Highest Number of Users in 7 days"></Form.Control>
                                                <Form.Control className="mt-2" type="text" placeholder="Supply url"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Lowest Trading Volume in 7 days</Form.Label>
                                                <Form.Control type="text" placeholder="Lowest Trading Volume in 7 days"></Form.Control>
                                                <Form.Control className="mt-2" type="text" placeholder="Supply url"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Average Weekly Users  </Form.Label>
                                                <Form.Control type="text" placeholder="average"></Form.Control>
                                            </Form.Group>
                                        </div>

                                        <div className="col-xl-4 col-xxl-4 pt-3">
                                            <h6 class="formhead text-capitalize">Monthly Visits</h6>
                                            <Form.Group className="mb-3 monthselect">
                                                <Form.Label>Highest Number of Users in a Month </Form.Label><br></br>                                               
                                                 <DatePicker
                                                    placeholderText="Month"
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    dateFormat="MM/yyyy"
                                                    showMonthYearPicker
                                                />
                                                <Form.Control className="mt-2" type="text" placeholder="Supply url"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3 monthselect">
                                                <Form.Label>Lowest Trading Volume in a Month  </Form.Label>
                                                <DatePicker
                                                    placeholderText="Month"
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    dateFormat="MM/yyyy"
                                                    showMonthYearPicker
                                                />
                                                <Form.Control className="mt-2" type="text" placeholder="Supply url"></Form.Control>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Average Monthly Users </Form.Label>
                                                <Form.Control type="text" placeholder="average"></Form.Control>
                                            </Form.Group>
                                        </div>
                                    </div>

        </>

    )
}

export default Trafficflow;